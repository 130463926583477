<script>
  
import Chart from 'chart.js'  
import { Doughnut } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
Chart.plugins.register(ChartDataLabels)

export default {
    extends: Doughnut,
    props:['data'],

    data(){
      return {
        
        total:0,
        olddata:{},
        chartdata: {
                hoverBackgroundColor: "red",
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: [],
                        data: [],
                        borderWidth: 0,
                    }
                    ]
            },

        chartoptions: {
                        plugins: {
                          datalabels: {
                            // color: "rgba(255,255,255,0.5)",
                            // textAlign: "center",
                            // font: {
                            //   size: 12
                            // }
                            display:false
                          }
                        },
      
                    hoverBorderWidth: 20,
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: true,
          },    
  };

},

  mounted () {
      this.buildChart()  
  },

  watch: {
              data: function(){
               if(JSON.stringify(this.data) !== JSON.stringify(this.olddata)){
                  this.buildChart()  
                }
            }
  },            

  methods:{

    buildChart(){
           
            // Clear previous data
            this.chartdata.datasets[0].data = []
            this.chartdata.datasets[0].backgroundColor = []
            this.chartdata.labels = []
            Object.keys(this.data).forEach(key => {
           
                    if(this.data[key].value !== 0){
                    this.chartdata.datasets[0].data.push(this.data[key].value)
                    this.chartdata.labels.push(this.data[key].label)
                    this.chartdata.datasets[0].backgroundColor.push(this.data[key].color)
                    }
                 
                   });
                   this.olddata = this.data
                   this.renderChart(this.chartdata, this.chartoptions)

            },

  },
}

</script> 