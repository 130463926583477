<template>
<div>

    <b-row>
        <b-col class="col-12 pa-5">
                 Stock subscriptions available
        </b-col>

        <b-col class="col-12 col-sm-6 col-xl-4 mt-3 p-0" v-for="stocksub in stocksubs" :key="stocksub.value" @click.stop="selectStockSub(stocksub.value)">

        <div class="box" style="min-height:80px">

          <b-row class="p-3">
            <b-col class="col-2 text-right">
              <h3><b-icon-collection-play /></h3>
            </b-col>

            <b-col class="col-10">
              <h6>{{ stocksub.text }}</h6>
              {{  stocksub.substatustext }}
            </b-col>
          </b-row>
        </div>
        </b-col>

        <b-col class="col-12 pa-5 mt-3">
                 Select a stock subscription or buy a new subscription
        </b-col>

        <b-col class="col-12 mt-5 pb-3" v-if="stocksel > 0">
            <b-button block variant="outline-success" @click.stop="assignSub()">Activate subscription</b-button>
        </b-col>

        <b-col class="col-12 mt-5 pb-3" v-else>
            <b-button block variant="outline-success" @click.stop="buySub()">Buy subscription</b-button>
        </b-col>

                
    </b-row>


</div>
</template>

<script>
export default {
    name: 'SubChoice',
    components:{
        
    },
    data(){
    return {
        selectedrover:{},
        selectedsub:{},
        stocksel:0
    };
},

created(){
    this.rovers = this.$store.getters.rovers
    this.stocksubs = this.$store.getters.stocksubs
    this.selectedrover = this.$store.getters.selectedrover
},


methods: {

selectStockSub(id){
    this.stocksubs.forEach(element => {element.substatustext = 'Available'});
    let sub = this.stocksubs.filter(sub => sub.value == id)[0]
    this.selectedsub = sub
    if(sub.substatustext == 'Available'){sub.substatustext = 'Selected'} 
    if(this.stocksel == id && sub.substatustext == 'Selected'){
      sub.substatustext = 'Available'
      this.stocksel = 0
    } else this.stocksel = id
  },

  assignSub() {
    this.$store.commit('setselectedsub',this.selectedsub)
    this.$store.commit('setselectedrover',this.selectedrover)
    this.$router.push({ name: 'assignsubscription', params: { id: 0} }).catch(() => { })
  },

  buySub(){
        this.menuLink('subscriptionadd')
  },

  menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },

    closeModal(){
      this.stocksubs.forEach(element => {element.substatustext = 'Available'})
    }

}
}
</script>