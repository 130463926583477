<template>
  <div style="max-width:100%">
    <b-container>
      <b-row>
        <b-col class="col-12 text-center mt-3" v-if="selectedrover == ''">
          <h3 >Buy subscriptions</h3>
        </b-col>
        <b-col class="col-12 text-center mt-3" v-else>  
          <h3>Buy subscription</h3>
        </b-col>
      </b-row>

      <b-row class="mt-3">

        <b-col class="col-12 col-md-8 offset-md-2">

          <b-row class="borderbox" style="margin-bottom:20px" v-if="selectedrover != ''">
            <b-col class="col-12 mt-3" >
              <h5>Rover Details</h5>
              <h6>{{ selectedrover.text }}</h6>
              <h6>{{ selectedrover.machine[0].text }}</h6>
            </b-col>
            <!-- <b-col class="col-12 mt-3" >
              {{ selectedrover }}
            </b-col> -->

          </b-row> 

          <b-row class="borderbox">

            <b-col class="col-12 mt-3">
              <h3><span v-if="sub.price > 0">£{{ sub.price }}</span></h3>
            </b-col>

            <b-col class="col-12 mt-3">

              <label>Select Subscription</label>
              <b-form-select v-model="subsel" :options="stypes" name="subsel" v-validate="{ min_value: 1 }"
                :class="{ 'b-form-select': true, 'is-danger': errors.has('subsel') }" class="darkinput" />
              <span>{{ errors.first('subsel') }}</span>


            </b-col>

            <b-col class="col-12 mt-3">
              <label>Quantity</label>
              <b-form-input type="search"
              placeholder="number required" 
              autocomplete="off" 
              v-model="subquant"
              name="subquant" 
              v-validate="{ min_value: 1, 
              max_value: 10000, 
              integer: true }"
              :class="{ 'darkinput': true, 'is-danger': errors.has('subquant') }" 
              :disabled = "selectedrover != ''"
              />
              <span>{{ errors.first('subquant') }}</span>
            </b-col>

            <b-col class="col-12 mt-3">

              <label>Roaming SIM included</label>
              <b-form-select v-model="simsel" :options="simmoptions" class="darkinput" disabled />

            </b-col>

            <b-col class="col-12 mt-3">
              <label>Subtotal</label>
              <p v-if="subsel != 0">£{{ subtotal }}</p>
            </b-col>

            <b-col class="col-12 mt-5">
              <b-button variant="outline-success" block @click.stop="validateAll()">Add to cart</b-button>
            </b-col>

          </b-row>
        </b-col>

        <b-col class="col-12 col-md-8 offset-md-2 p-0">
          <PaymentBasket />
        </b-col>

      </b-row>

      <b-row class="pb-5">
        <b-col class="col-12 col-md-8 offset-md-2 mt-3" v-if="baskettotal > 0">
          <b-button block variant="outline-success" @click="menuLink('basketcheckout')">Checkout</b-button>
        </b-col>
      </b-row>

      <b-modal ref="item-added" size="md" hide-header hide-footer centered content-class="darkmodal">
        <b-row class="pt-3 pb-3">
          <b-col class="col-12 text-center">
            <h5>Basket updated</h5>
          </b-col>
        </b-row>
      </b-modal>

    </b-container>
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import PaymentBasket from './PaymentBasket'
import axios from 'axios'
import { mapState } from 'vuex'
import ErrorLogging from './ErrorLogging.vue';

export default {
  name: 'SubscriptionAdd',
  components: {
    PaymentBasket,
    ErrorLogging,
  },
  data() {

    return {
      token: '',
      userid: 0,
      subsel: 0,
      simsel: 0,
      sub: {},
      subquant: 1,
      subtotal: 0,
      basketitems: [],
      stypes: [{ value: 0, text: 'Select' }],
      simmoptions: [
        { value: 0, text: '-' },
        { value: 1, text: 'No' },
        { value: 2, text: 'Yes' }
      ],
      selectedrover:'',
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.$store.commit('setpaymentcomplete', false)
    this.$store.commit('setpayment', {})
    this.$store.commit('setpaymentintent', '')
    this.basketitems = this.$store.getters.basket
    this.selectedrover = this.$store.getters.selectedrover
    this.fetchSubs() // get sub types and prices
  },


  computed: mapState(['basket', 'baskettotal', 'paymentcomplete']),

  watch: {
    subsel() {
      if (this.subsel == 0) { return }
      this.sub = this.stypes.filter(sub => sub.value == this.subsel)[0]
      this.subtotal = this.sub.price * this.subquant
      if (this.sub.simm == 1) { this.simsel = 2 } else this.simsel = 1
    },

    subquant() {
      this.subtotal = this.sub.price * this.subquant
    },

  },

  methods: {

    fetchSubs() {
      axios.get('/api/portalsubscriptionprices', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.userid,
          token: this.token,
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data;
          } else {
            this.stypes.push(...response.data.subs)
          }
        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    validateAll() {
      this.$validator.validateAll().then(result => {
        if (!result) return;
        this.addtoBasket();
      })
    },

    addtoBasket() {
      let selsub = this.sub.value
      this.sub.stext = this.sub.text

      this.basketitems.push({ subid: selsub, subtitle: this.sub.stext, subquant: this.subquant, subprice: this.sub.price, subtotal: this.sub.price * this.subquant, subsimm: this.sub.simm })
      this.$store.commit('setbaskettotal', this.$store.getters.baskettotal + this.sub.price * this.subquant)
      this.$store.commit('setbasket', this.basketitems)
      this.sub = {}
      this.subsel = 0
      this.simsel = 0
      this.subquant = 1
      this.$refs['item-added'].show()
      setTimeout(() => { this.$refs['item-added'].hide() }, 2000);
    },


    menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },
  },
};

</script>