<template>
  <div ref="mapcont"  style="position:relative" class="borderlight">   

    <div class="titleholder">
      <span v-if="!dataerror">
          <span v-if="border"> SESSION TRACK</span> 
          <span v-if="loading"> UPDATING</span>
      </span>
      <span v-if="dataerror" style="color:red">NO SESSION TRACKING DATA</span>
    </div>

    <div class="leafletbuttons">
      <b-button variant="outline-success" size="sm" @click.stop="showSat()" ><b-icon-image /></b-button>
      <b-button variant="outline-success" size="sm" @click.stop="resetBounds()" >
          <b-icon-arrow-clockwise  />    
      </b-button>   
    </div> 
    
    <div id="mapholder" ref="myMap" style="height: 400px;position:relative;"></div>
                    
    <div class="leafletrtk">
      <div class="rtkstatus" style="background-color:rgba(60, 210, 165, 0.7)">FIXED</div>
      <div class="rtkstatus" style="background-color:rgba(48, 190, 255, 0.7)">FLOAT</div>
      <div class="rtkstatus" style="background-color:rgba(255, 159, 12, 0.7)">DGPS</div>
      <div class="rtkstatus" style="background-color:rgba(255, 96, 96, 0.7);min-width:46px;text-align:center">GPS</div>
      <div class="rtkstatus" style="background-color:rgba(102, 102, 102, 0.7);min-width:46px;text-align:center">EST</div>
    </div>
                                    
    <b-modal ref="linedetail-modal" size="md" :title="currentline.title" hide-footer centered>  
      <b-row class="mt-3">
            
        <b-col class="col-6">Quality</b-col>
        <b-col class="col-6" >{{ currentline.statustext }}</b-col>
      
        <b-col class="col-6">Num Satellites</b-col>
        <b-col class="col-6">{{ currentline.satelites }}</b-col>
        
        <b-col class="col-6">Altitude</b-col>
        <b-col class="col-6">{{ currentline.altitude }}m</b-col>
        
        <b-col class="col-6">HDOP</b-col>
        <b-col class="col-6">{{ currentline.hdop }}</b-col>
        
        <b-col class="col-6">Data Age</b-col>
        <b-col class="col-6">{{ currentline.age }}</b-col>
               
      </b-row>
    </b-modal>
    
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
    
  </div> 
</template>
 
<style scoped>
.leafletbuttons{
  left:10px;
  bottom:10px;
}
.leafletrtk{
  right:10px;
  bottom:10px;
}
</style>
    
<script>
  import ErrorLogging from './ErrorLogging.vue'
  import L from 'leaflet'
  import { latLng } from "leaflet"
  import 'leaflet/dist/leaflet.css'
  import mapmarker from '@/assets/images/mapmarker.png'
  import mapmarker2 from '@/assets/images/mapmarker2.png'
  import spin from '@/assets/images/process.gif'
  import axios from 'axios'
  import moment from 'moment'
    
  delete L.Icon.Default.prototype._getIconUrl
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    }
  );
    
  export default {
    props:['fsmapheight','fsmapwidth','border','fullscreen','mapdata','id','sessionid','reset'],
    name:'MapLeafletRoverSession',
    components: {
        ErrorLogging
    },
    data(){
      return {
        spin,
        pointcount:0,
        currentsession:{},
        currentline:{},
        rover:{},
        showsat:false,
        start:true,
        markers: [],
        points:[],
        markerLayer:{},
        polylineLayer:{},
        map:{},
        centered:false,
        notzoomed:true,
        staticAnchor: [5,20],
        currentZoom:15,
        currentCenter: [50.97584116666667, -0.6396681666666667],
        satlayergroup: {},
        mapheight:0,
        mapwidth:0,
        mapshow:false,
        mapmarker,
        mapmarker2,
        loading:true,
        dataerror:false,
        errorresponse:{},
        linedetailmodal:false
      };
              
    },

    watch: {
      mapdata: function () {
        this.currentsession = this.mapdata
        this.pointcount = this.currentsession.points.length 
        if(this.pointcount == 0){this.dataerror = true} else this.dataerror = false
        this.updateMap();
      }, 

      reset: function (){
          this.start = true
      },
                    
      sessionid: function () {
        this.start = true;
      },
     
    },

    mounted(){
      this.currentsession = this.mapdata
      this.setMapSize(1);
    }, 
      
    beforeUnmount() {
      //window.removeEventListener("resize",this.setMapSize(0));
    },  
      
      
    methods: {

      setMapSize(build){
        if(this.fsmapheight == undefined){
          this.mapwidth = this.$refs.mapcont.clientWidth + 24;
          this.mapheight = this.$refs.mapcont.clientHeight + 24;
        } else {
          this.mapheight = this.fsmapheight;
          this.mapwidth = this.fsmapwidth;
        }
        
        if(build == 1){this.buildMap()}
      },

      buildMap(){
        this.map = L.map('mapholder', {
          center: this.currentCenter,
          zoom: this.currentZoom,
          doubleClickZoom: false,
          //zoomAnimation:false
        }); 

        // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //     maxZoom: 19,
        //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        // }).addTo(this.map);

        L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map);  

        this.markerLayer = L.layerGroup().addTo(this.map)
        this.polylineLayer = L.layerGroup().addTo(this.map)
        this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});  
        this.mapshow = true
        this.updateMap()    
      },

      onReady() {
        // this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});
        //this.updateMap()
      },

      updateMap(){
        this.loading = true;
        this.points = [];
        this.markers = [];
      
        let index = 0;    
        let points = this.currentsession.points;
        let mpoints = [];

        this.markerLayer.clearLayers();
        this.polylineLayer.clearLayers();

          let seticon = ''              
          this.currentsession.markers.forEach(element => {
            if(element.type == 1){seticon = this.mapmarker; } else seticon = this.mapmarker2; 

            const icon = L.icon({
              iconUrl: seticon,
              iconSize: [15, 21], // size of the icon
              iconAnchor: [8, 21], // point of the icon which will correspond to marker's location
              popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
              shadowSize: [41, 41], // size of the shadow
            });

            
              L.marker([element.lat, element.long], { icon }).addTo(this.markerLayer);   
             
          });
          
          if(points.length > 0){
            this.dataerror = false;
                    
            do {

                mpoints.push([points[index]['lat'],points[index]['long']]);
                mpoints.push([points[index+1]['lat'],points[index+1]['long']]);
                L.polyline(mpoints, { color: points[index]['color'] }).addTo(this.polylineLayer);
                mpoints = [];
                index++
                    
            } while (index < points.length - 1)

            this.points.push(...points) //  Add line points to main points list (only used to set page boundaries)

            if(this.start == true ){
              this.start = false;
                this.setBounds();
              // this.toTop();
            } 
          } 
          this.loading = false;  
      },

      setBounds(){
        if(this.points.length > 1){
          this.map.fitBounds(this.points.map(m => { return [m['lat'], m['long']] }),{padding: [45, 45]});
        }
      },

      toTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    
      zoom(){
        this.notzoomed = !this.notzoomed;
        // if(!this.notzoomed){
        //         this.currentZoom = 16
        //         this.centerMap() 
        //     } else this.setBounds()
          
      },

      centerMap(){
        if(this.markers.length == 1){
          this.$refs.myMap.mapObject.setView(latLng(this.markers[0]['lat'],this.markers[0]['long']),this.currentZoom); // Zoom and Focus to current position
          this.centered = true;
        }
        if(this.markers.length > 1){
          this.$refs.myMap.mapObject.setView(latLng(this.markers[1]['lat'],this.markers[1]['long']),this.currentZoom); // Zoom and Focus to current position
          this.centered = true;
        }
      },

      showSat(){
        if(this.showsat == false) {
          this.satlayergroup.addTo(this.map);
          this.showsat = !this.showsat;
        }else {
          this.satlayergroup.remove();
          this.showsat = !this.showsat;
        } 
      },
        
      lineClick(){
        //this.showDetail(line.time)
      },

      showDetail(time){
        axios.get('/api/portalrovermessages',{
          headers: {"Authorization" : "Bearer " + this.$store.getters.token},
          params:{
              userid:this.$store.getters.user.id,
              token:this.$store.getters.token,
              id:this.id,
              time:time
          }
        })
        .then((response) => {
          if(response.data.code == 999){
            this.errorresponse = response.data;
          } else {
            this.currentline = response.data.Requests[0];
            this.currentline.title = moment(this.currentline.timedate).format('MMM Do YYYY HH:mm:ss');
            this.$refs['linedetail-modal'].show();
          }    
        })         
      },

      resetBounds(){
        this.setBounds();
        //this.$emit('fullscreen');
      },
          
    }	   
  
  };

</script> 