<template>
  <div  :class="setimageborder">
    <div class="leafletbuttons">
      <b-button variant="outline-success" size="sm" @click.stop="resetZoom()"><b-icon-arrow-clockwise v-b-tooltip.hover
          title="Refresh" /></b-button>
      <b-button variant="outline-success" size="sm" @click.stop="toggleSat()"><b-icon-image v-b-tooltip.hover
          title="Show terrain" /></b-button>
      <!-- <b-button v-if="border" variant="outline-success" size="sm" @click.stop="fullScreen()"><b-icon-fullscreen
          v-b-tooltip.hover title="Expand" /></b-button> -->
    </div>

    <div :id="mapcont"  :style="{ height: mapheight + 'px',width: mapwidth + 'px'}" ></div>

    <div class="leafletrtk">
      <div class="rtkstatus" style="background-color:rgba(60, 210, 165, 0.7)">FIXED</div>
      <div class="rtkstatus" style="background-color:rgba(48, 190, 255, 0.7)">FLOAT</div>
      <div class="rtkstatus" style="background-color:rgba(255, 159, 12, 0.7)">DGPS</div>
      <div class="rtkstatus" style="background-color:rgba(255, 96, 96, 0.7);min-width:46px;text-align:center">GPS</div>
      <div class="rtkstatus" style="background-color:rgba(102, 102, 102, 0.7);min-width:46px;text-align:center">EST
      </div>
    </div>


  </div>
</template>

<style scoped>

.mapholder{
  position:relative;
  left:2px;
  top:-10px;
}

.lmarkerhold {

  width: 10px;
  height: 10px;
  border-radius: 5px;
  z-index: 998;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.70);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.70);
  }

}

.leafletbuttons{
    top:20px;
    right:20px;
    left:auto;
  }

</style>

<script>
import { mapState } from 'vuex'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import L from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet/dist/leaflet.css'
import { point as Point } from "leaflet"

export default {
  props: ['border','mapwidth','mapheight'],
  name:'MapLeafletRovers',
  data() {
    return {
      token: '',
      rovers: [],
      roverslist: [],
      points: [],
      pointsfix: [],
      staticAnchor: [0, 0],
      fontsize: '1em',
      mapshow: false,
      showsat: false,
      setimageborder: 'setmapmin borderlight',
      timeout: '',
      currentZoom: 6,
      mapcont: 'mapcont',
      satlayergroup: {},
      currentCenter: [51.505, -0.09],
      setbounds: true,
    };   
      
  },

  beforeDestroy() {
    this.setbounds = false
  },

  created() {
    this.rovers = this.$store.getters.roversmap
  },

  mounted() {
    //this.setMapSize()
    this.rovers = this.$store.getters.roversmap
    
    this.markers = L.markerClusterGroup({
     
     iconCreateFunction: function(cluster) {

       let markers = cluster.getAllChildMarkers()
       let childCount = cluster.getChildCount();
       let rtkzero = 0
       let rtkfix = 0
       let rtkfloat = 0
       let rtkdgps = 0
       let rtkstand = 0
       let status = 0
       let values = ''
      
       markers.forEach(element => {
       status = element.status
      
       switch (status){
         case 0:
         rtkzero++
         break
         case 1:
         rtkstand++
         break
         case 2:
         rtkdgps++
         break
         case 4:
         rtkfix++
         break
         case 5:
         rtkfloat++
         break
       }

         })

       let first = 0
       if(rtkzero != 0){
         rtkzero = (rtkzero/childCount * 100) * 3.6
         values += 'rgba(128, 0, 128, 0.7) '+first+'deg '+Number(rtkzero+first)+'deg,'
         first = Number(rtkzero+first)
         }
       if(rtkstand != 0){
         rtkstand = (rtkstand/childCount * 100) * 3.6
         values += 'rgba(255, 96, 96, 0.7) '+first+'deg '+Number(rtkstand+first)+'deg,'
         first = Number(rtkstand+first)
         }
       if(rtkdgps != 0){
           rtkdgps = (rtkdgps/childCount * 100) * 3.6
           values += 'rgba(255, 159, 12, 0.7)  '+first+'deg '+Number(rtkdgps+first)+'deg,'
           first = Number(rtkdgps+first)
           }
       if(rtkfloat != 0){
           rtkfloat = (rtkfloat/childCount * 100) * 3.6
           values += 'rgba(48, 190, 255, 0.7) '+first+'deg '+Number(rtkfloat+first)+'deg,'
           first = Number(rtkfloat+first)
         }
       if(rtkfix != 0){
           rtkfix = (
           rtkfix/childCount * 100) * 3.6
           values += 'rgba(60, 210, 165, 0.7) '+first+'deg '+Number(rtkfix+first)+'deg,'
         }
         
         values =  values.slice(0, -1)

         return L.divIcon({
                     html: "<div class='leafletcluster' style='background-image: conic-gradient("+values+");'><span class='cmarker'>"+childCount+"</span></div>",
                     className: "xpietest",
                     iconSize: new Point(40, 40)
                   });

    }
    });

    this.buildMap()
  },

  computed: mapState(['roversmap']),

      watch:{
          roversmap () {
              console.log('rovermap')
              this.rovers = this.roversmap
              this.addMarkers()
          },
  },          

  methods: {

    buildMap(){

        this.map = L.map(this.mapcont, {
                center: this.currentCenter,
                zoom: this.currentZoom,
                doubleClickZoom: false,
              // zoomAnimation:false
        }); 

        // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //     maxZoom: 19,
        //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        // }).addTo(this.map);

        L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map)   
            
        this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});  
        
        this.addMarkers()
    },


    addMarkers() {

          let customIcon = ''
          let color = ''
          this.points = []
          this.markers.clearLayers();
          this.map.removeLayer(this.markers);

          this.rovers.forEach(element => {
                if(element.latitude != null){

                color = 'background-color:'+element.color
            
                customIcon = L.divIcon({
                className: 'custom-icon',
                html: '<div class="lmarkerhold" style="'+color+'"></div><div class="marker-text" style="'+color+'">'+element.text+'</div>',
                iconSize: [80, 40], // Width and height of the icon
                iconAnchor: [40, 20] // Center point of the icon
              }); 

              if(element.rtk_fix_status >= 0 && element.rtk_fix_status < 5){
                  var marker = L.marker([element.latitude, element.longitude], { icon: customIcon },{ title: element.text },{ status:element.rtk_fix_status });
                  marker.status = element.rtk_fix_status
                  marker.id = element.value
                  //marker.bindPopup(element.text)
                  this.markers.addLayer(marker)
                  // create fit bounds array
                  this.points.push({lat:element.latitude, lng:element.longitude})
                  // Make Clickable
                  let self = this
                  marker.on('click', function() {
                      self.viewRover(marker.id)
                  })

                  }

                }
                
            })  

              this.map.addLayer(this.markers)
              if(this.start == true){
                this.start = false
                this.setBounds()
              }
   
    },

    setBounds(){
          if(this.points.length > 1){
            this.map.fitBounds(this.points.map(m => { return [m['lat'], m['lng']] }),{padding: [45, 45]})
          }
    },

    viewRover(id){
      console.log(id)
      this.$router.push({ name: 'casterrover', params: {id:id} }).catch(()=>{})
    },

    showSat(){
              if(this.showsat == false){
                  this.satlayergroup.addTo(this.map)
              } else  this.satlayergroup.remove() 
          },

    
    setCountry() {
      switch (this.company.country) {
        case 0:
          this.currentCenter = [55.128541689922706, 2.9889090697207674]
          break;
        case 1:
          this.currentCenter = [54.188255397553895, -2.482830042690006]
          break;
        case 2:
          this.currentCenter = [55.959618925597255, 9.13289438112091]
          break;
      }
    },


    
    setCenter() {
      if (this.pointsfix.length == 1) {
        this.currentCenter = [this.pointsfix[0].lat, this.pointsfix[0].lng]
      }
    },

    resetZoom() {
      this.setbounds = true
      this.setBounds()
    },

    toggleSat() {
      if(this.showsat == false) {
          this.satlayergroup.addTo(this.map);
          this.showsat = !this.showsat;
        }else {
          this.satlayergroup.remove();
          this.showsat = !this.showsat;
        } 
    },

    setMapSize() {
        // this.mapwidth = this.$refs.mapcont.clientWidth + 0
        // this.mapheight = this.$refs.mapcont.clientHeight + 0
        // this.mapshow = true
    },

    fullScreen() {
      this.$emit('fullscreen')
    }

  }

};

</script>