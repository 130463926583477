<template>
<div>
<b-container>

    <b-row class="borderbox mt-5" v-if="baskettotal ==0">
            <b-col class="col-12 mt-3">  
                <h5>No items in cart</h5>
            </b-col>
    </b-row>          

    <b-row class="borderbox mt-5" v-else>
            <b-col class="col-6 mt-3">  
              <h6 style="margin-bottom:10px">Cart</h6>
            </b-col>

            <b-col class="col-6 text-right mt-2">  
              <b-btn variant="outline-danger" size="sm" @click.stop="clearBasket()"><b-icon-trash style="margin-right:5px" />Clear basket</b-btn>
            </b-col>

            <b-col class="col-12 mt-3" v-if="mobile">
              <b-row class="mb-3 custom-outline" v-for="item in basketitems" :key="item.id">
                  <b-col class="col-6">Subscription</b-col>
                  <b-col class="col-6">{{  item.subtitle  }}</b-col>
                  <b-col class="col-6">Price</b-col>
                  <b-col class="col-6">£{{  item.subprice  }}</b-col>
                  <b-col class="col-6">Quantity</b-col>
                  <b-col class="col-6">{{  item.subquant  }}</b-col>
                  <b-col class="col-6">Subtotal</b-col>
                  <b-col class="col-6">£{{  formatPrice(item.subtotal) }}</b-col>
              </b-row>
            </b-col>

            <b-col class="col-12 mt-3" v-else>  
              <b-table 
                striped 
                  responsive 
                  hover
                  :items="basketitems" 
                  :fields="fields"
                  tbody-tr-class="rowClass"
                  thead-tr-class="rowHClass"
                  dark
                  >
               <template #cell(change_quants)="row">
                       <b-form-input
                          style="width:70px"
                          size="sm"
                          placeholder="number required"
                          autocomplete="off" 
                          v-model="row.item.subquant"
                          @change="changeQuants(row)" 
                          name="row.item.subquant"
                          v-validate="{min_value:1}"
                          :class="{'darkinput': true, 'is-danger': errors.has('row.item.subquant') }"
                      />
                 </template>

                <template #cell(clear_item)="row">
                    <b-icon-trash class="quantselecticon" style="color:white;font-size:1.4em" @click.stop="itemClear(row)" />
                </template> 

              </b-table>

            </b-col>
    
            <b-col class="col-12 col-sm-8 p-3">
                <b-table-simple class="totals" small borderless responsive>
                      <b-tbody>
                          <b-tr>
                                <b-td>Subtotal</b-td>
                                <b-td>£{{ total }}</b-td>
                          </b-tr>
                          <b-tr v-if="discount > 0">
                                <b-td>Coupon Discount {{ coupondiscount }}%</b-td>
                                <b-td>£{{ discount }}</b-td>
                          </b-tr>
                            <b-tr>
                                <b-td>VAT</b-td>
                                <b-td>£{{ vat }}</b-td>
                          </b-tr>
                            <b-tr>
                                <b-td>Total</b-td>
                                <b-td>£{{ totalincvat }}</b-td>
                          </b-tr>
                      </b-tbody>
                </b-table-simple>          

            </b-col>

            <b-col class="col-12 mt-3">
            <b-row v-if="couponapplied">
              <b-col class="col-12">
                  <b-button variant="info" block size="md" disabled>Coupon {{ couponvalue }} 10% discount applied</b-button>
              </b-col>
            </b-row>

            <b-row v-else>
              <b-col class="col-12" style="border: 1px solid gray;margin-left: 15px;max-width: 94%;padding: 15px;"> 
                <b-row>
                  <b-col class="col-12 col-sm-6">
                    <label>COUPON</label>
                  </b-col>   

                  <b-col class="col-12 col-sm-6">
                     <b-form-input 
                            id="darksearch"
                            type="search" 
                            size="sm"
                            v-model="couponvalue" 
                            autocomplete="off" 
                            placeholder="enter"
                            class="darkinput"
                            @change="setCoupon()"
                            />
                      <h5 v-if="!couponvalid && couponvalue !=''" style="color:red">COUPON INVALID</h5>      
                    </b-col>
              </b-row>
              </b-col>      

            </b-row>    
      </b-col>


            
    </b-row>

</b-container>
</div>
</template>

<style scoped>
.custom-outline {
  border: 1px solid #727380;
  padding: 5px;
  border-radius: 4px; 
}
</style>

<script>
import { mapState } from 'vuex'
export default {
    name: 'BasketDisplay',
    components: {
    
    },
data: function () {
  return {
    basketitems:[],
    couponvalid:false,
    couponapplied:false,
    couponvalue:'',
    coupondiscount:10,
    total:0,
    vat:0,
    discount:0,
    totalincvat:0,
    fields:[
    { key: 'subtitle', label: 'Subscription' },
    { key: 'change_quants', label: 'Quantity'},
    { key: 'subprice', label: 'Price',
        formatter(value) {
              return '£'+`${value}`; 
        }},
    { key:'subtotal', label: 'Subtotal', 
        formatter(value) {
              return '£'+`${value}`; 
        }},
     { key: 'clear_item', label: ''},      
    ],
   
  };
},
mounted() {
    this.basketitems = this.$store.getters.basket
    this.couponvalue = this.$store.getters.coupon
    if(this.couponvalue == 'BEN10'){this.couponvalid = true}
    this.updateBasket()
 },

computed: mapState(['basket','baskettotal','mobile']),

watch: {
	
      basket(){
        this.basketitems = this.basket
        this.updateBasket()
      },

      couponvalue(newVal) {
      if (newVal === '') {
        this.setCoupon()
        }
      },  
},   

methods: {

         changeQuants(row){
                row.item.subtotal = row.item.subquant * row.item.subprice
                this.updateBasket()
            },

         updateBasket(){
            
               let total = 0
                 
                 this.basketitems.forEach(element => {
                    total = parseFloat(element.subtotal) + parseFloat(total)  
                });

                this.total = total.toFixed(2)
                 //COUPON CODE
                  if(this.couponvalid && this.couponvalue != ''){
                    this.discount = parseFloat(this.total/100 * this.coupondiscount).toFixed(2)
                    total = this.total - this.discount
                  } else {
                    this.discount = 0
                  }
                  //
                this.vat =  (total/100 * 20).toFixed(2)
                this.totalincvat = this.setTotalIncVAT(total,this.vat) 
                this.$store.commit('setbaskettotal',total)
                this.$store.commit('setbasket',this.basketitems)
          },

            formatPrice(price){
              return price.toFixed(2)
            },  

            itemClear(row){
                    this.basketitems.splice(row.index, 1);
                    this.updateBasket()
            },    
        
            addPound(value){
                        return '£'+value
                      },   

             setTotalIncVAT(total,vat){
                    let sub = parseFloat(total) + parseFloat(vat)
                    return sub.toFixed(2)
             },

             clearBasket(){
                  this.$store.commit('setbasket',[])
                  this.$store.commit('setbaskettotal',0)
             },

              setCoupon(){
                console.log(this.couponvalue)
                if(this.couponvalue == ''){
                  this.discount = 0
                  this.couponvalid = true
                  this.$store.commit('setcoupon',this.couponvalue)  
                  } else {
                    this.couponvalue = this.couponvalue.toUpperCase()
                    if(this.couponvalue == 'BEN10' ){
                      this.couponvalid = true
                      this.$store.commit('setcoupon',this.couponvalue)
                    } else this.couponvalid = false
                 }
                 this.updateBasket()
           },
    },
};
</script>