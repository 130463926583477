<template>
  <div>

    <b-container>

      <b-row class="mb-3">

        <b-col class="col-12 mt-3">
          <label>Manufacturer</label>
          <b-form-input name="Manufacturer" type="search" placeholder="required" autocomplete="off" v-model="maker.text"
            :disabled="lockfield" v-validate="'required|alreadyExists|min:3|max:32'" />
          <div class="text-danger">{{ errors.first('Manufacturer') }}</div>
        </b-col>

      </b-row>

      <b-row class="mt-5 pt-3 border-top">
        <b-col class="col-12">
          <b-button variant="outline-success" @click="updateMaker()" block><b-icon-check-circle
              class="mr-2" />{{buttonText}}</b-button>
        </b-col>
      </b-row>


    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>


<script>
import { Validator } from 'vee-validate';
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {

  props: ['id', 'makers'],
  components: {ErrorLogging},
  data() {
    return {

      token: '',
      maker: {},
      lockfield: false,
      errorresponse: {},
      buttonText: 'Save'
    };

  },

  created() {
    this.token = this.$store.getters.token
    if (this.id == 0) { this.newMaker() } else this.fetchMaker()

  },

  mounted() {
    this.addAlreadyExistsRule();
  },

  methods: {
    addAlreadyExistsRule() {
      //adding a rule to vee validator to check if the manufacturer already exists
      Validator.extend('alreadyExists', {
        getMessage: "Manufacturer already exists.",
        validate: (value) => {
          const makersText = this.makers.map(makerObj => makerObj.text.toLowerCase());
          if (makersText.includes(value.toLowerCase())) return false;
          return true;
        }
      })
    },

    fetchMaker() {
      axios.get('/api/machinemakers', {
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
            return;
          }
          this.maker = response.data.data
        })
        .catch(err => {
          this.errorresponse = err;
        });
    },


    newMaker() {

      this.maker.value = 0
      this.maker.text = ''

    },


    updateMaker() {
      if (this.lockfield) return;
      this.lockfield = true;

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.lockfield = false;
          return;
        }

        //JM - Is this 'if' code accessible any more, I cant find anywhere an edit maker option?
        //for now ive just swapped swal to alert. needs error logging and user aknowlagment if staying
        if (this.maker.value !== 0) {
          axios({
            method: 'put',
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/machinemaker',
            data: {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
              makerdata: this.maker
            }
          })
            .then((response) => {
              if (response.data.code == 999) {
                this.errorresponse = response.data
                return;
              }

              if (response.data.error) {
                this.errorresponse = response.data
                return;
              }
              /* 
              this.$swal({
                title: "Updated",
                text: 'Machine Maker updated',
                icon: "success",
                showCloseButton: true,
                showConfirmButton: false,
                timer: 3000
              })
              .then(() => {
              
                this.$emit('updatemakers', response.data.value)
              })
              */
             alert('Machine Maker Updated');
             this.$emit('updatemakers', response.data.value)
            }).catch(err => {
              /*
              this.$swal({
                title: "Update Error",
                text: err,
                icon: "error",
                showCloseButton: true,
                showConfirmButton: false
              })
              */
             alert('Update Error',err)
            }).finally(() => {
              this.lockfield = false;
            });
        } else {


          axios({
            method: 'post',     //   New Machine Maker
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/machinemaker',
            data: {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
              makerdata: this.maker
            }
          })
            .then((response) => {
              if (response.data.code == 999) {
                this.errorresponse = response.data
                return;
              }
              if (response.data.error) {
                this.errorresponse = response.data
                return;
              } 

              this.buttonText = 'Manufacturer Added';
              setTimeout( () => {
                this.$emit('updatemakers', response.data);
              },2000)

            }).catch(err => {
              this.errorresponse = err;
            }).finally(() => {
              this.lockfield = false;
            });
        }

      });

    },

    closemodal() {
      this.$emit('closemodal')
    },

  }

};

</script>
