<template>
<div>
<b-container>
        
      <b-row>
          <b-col class="col-sm-6">
            <b-icon-geo class="homepageicon" />
            <h4>Rovers</h4>
          </b-col>
    
          <b-col class="col-6 text-right">
            <b-button variant="outline-success" size="sm" @click.stop="menuLink('roversdisplay')"
              style="margin-right:5px"><b-icon-map /></b-button>
            <b-button variant="outline-success" size="sm" @click.stop="addRover()"><b-icon-plus-circle /></b-button>
          </b-col>
        </b-row>
    
        <b-row  v-if="roverslive.length == 0" lass="mt-5 border m-5 pt-3 pb-5">
          <b-col class="col-12 mt-3 text-center">
            <h5>No Rovers Found</h5>
          </b-col>
        </b-row>
    
            <b-row class="mt-3 border pt-1 pb-1 mb-3" v-if="roverslive.length != 0"> 
              <b-col class="col-12" style="color:rgba(255,255,255,0.7)">
                <div class="rtkstats">FIXED {{ fix }}</div>
                <div class="rtkstats">FLOAT {{ float }}</div>
                <div class="rtkstats">DGPS {{ dgps }}</div>
                <div class="rtkstats">GPS {{ gps }}</div>
                <div class="rtkstats">EST {{ est }}</div>
              </b-col>    
            </b-row>

        <b-row>
   
          <b-col class="col-12 col-sm-4 p-3 boxrover" v-for="roverlive in roverslive" :key="roverlive.id"
            @click.stop="viewRover(roverlive)">

              <b-row>
    
                <b-col class="col-12 mt-1">
                  <h5>{{ roverlive.text }}</h5>
                </b-col>
    
               
                <b-col class="col-12 mt-1 machinebox">
                  {{ roverlive.machine[0].make_title }} {{ roverlive.machine[0].model_title }}<br />
                  {{ roverlive.machine[0].regnum }}
                </b-col>
              </b-row>
    
              <b-row v-if="roverlive.subscriptiondetail.length > 0" class="mt-3">
                <b-col class="col-6">RTK Status</b-col>
                <b-col class="col-6" v-if="roverlive.rtk_status != 0">
                  <b-button :style="{ backgroundColor: setRTKColor(roverlive.rtk_status) }" block size="sm">{{ roverlive.statustext
                    }}</b-button>
                </b-col>
                <b-col class="col-6" v-else>
                  <b-button :style="{ backgroundColor: 'rgba(0,0,0,0.7)' }" block size="sm">not connected</b-button>
                </b-col>
              </b-row>
    
              <!-- Rover has a subscription -->
              <b-row class="mt-3" v-if="roverlive.subscriptiondetail.length > 0">
                <b-col class="col-6">
                  Subscription
                </b-col>
                <b-col class="col-6" v-if="roverlive.subscriptiondetail[0].status != 'Scheduled'">
                  <b-button :style="{ backgroundColor: setExpiryColor(roverlive.subscriptiondetail[0].expirydays) }" block
                    size="sm">{{ roverlive.subscriptiondetail[0].expirydays }} days</b-button>
                </b-col>
    
                <b-col class="col-6" v-else>
                  <b-button :style="{ backgroundColor: 'rgba(60, 210, 165, 0.7)' }" block
                    size="sm">{{ roverlive.subscriptiondetail[0].status }} <br/> {{ formatDate(roverlive.subscriptiondetail[0].startdate) }}</b-button>
                </b-col>
              </b-row>
    
              <!-- Show Renew Buy options -->
              <b-row class="mt-3" v-if="roverlive.subrenew">
                <b-col class="col-12">
                <b-button variant="outline-warning" block @click.stop="renewChoice(roverlive.value)">Renew subscription</b-button>
              </b-col>
              </b-row>
    
            </b-col>
    
        </b-row>

        <b-row>
            <b-col class="col-12 col-sm-6 offset-sm-3 mt-5 text-center">
                <b-button variant="outline-success" block  @click.stop="addRover()"><b-icon-plus-circle /> ADD NEW ROVER</b-button>
            </b-col>
        </b-row>

    
        <b-modal ref="rover-status" :title="rovertitle" hide-footer centered content-class="darkmodal">
        <b-row>
            <b-col class="col-12 pa-5 mb-3">
                     Rover not connected
            </b-col>
    
            <b-col class="col-12 pa-5 mb-3">
                   <b-button variant="outline-success" block @click.stop="editRover(selectedrover)">Manage Rover</b-button>
            </b-col>
    
        </b-row>
        </b-modal>    
    
        <b-modal ref="renew-choice" title="Renew" @hide="closeModal" hide-footer centered content-class="darkmodal">
            <SubChoice  />
        </b-modal>
     
        <LoadCompanyData :reloaddata="reloaddata" :dialog="false" />
    
      </b-container>
      </div> 
      </template>
    
<style>
.rtkstats{
    position: relative;
    float: left;
    width:20%;
    text-align: center;
}

.machinebox{
    min-height:50px;
}
</style>
    <script>
    import SubChoice from './SubChoice.vue'
    import LoadCompanyData from './LoadCompanyData'
    import { mapState } from 'vuex'
    import moment from 'moment'
    export default {
    name: 'RoversList',
    components:{
        SubChoice,
        LoadCompanyData
    },
    data(){
    return {
            roversdata:[],
            roverslive:[],
            stocksubs:[],
            stocksel:0,
            selectedsub:{},
            selectedrover:{},
            rovertitle:'',
            showchoice:false,
            fix:0,
            float:0,
            gps:0,
            dgps:0,
            est:0,
            connected:0,
            reloaddata:0,
            timeout:false,
            };
        },

    beforeDestroy () {
            clearInterval(this.timeout)
    },    

    created(){
        this.setRoversData(this.$store.getters.rovers)
        this.stocksubs = this.$store.getters.stocksubs
        this.timeout = setInterval(this.getRoverdata,5000)
    },

    computed: mapState(['rovers']),

    watch: {
        rovers: function() {
            this.setRoversData(this.rovers)
        }
    },

    methods: {

        setRoversData(rovers){
            let subrenew = false
            rovers.forEach(element=>{
                if(element.subscriptiondetail.length == 1){
                if(element.subscriptiondetail[0].expirydays > 1){subrenew = false} else subrenew = true
                } else subrenew = true
                element.subrenew = subrenew
            });

            this.roverslive = rovers
            this.setStats()
        },

        getRoverdata(){
            this.reloaddata = 2
            setTimeout(() => {this.reloaddata = 0}, 2000);
        },

        setStats(){
            this.gps = this.roverslive.filter(roversel => roversel.rtk_status == 1).length
            this.dgps = this.roverslive.filter(roversel => roversel.rtk_status == 2).length
            this.fix = this.roverslive.filter(roversel => roversel.rtk_status == 4).length
            this.float = this.roverslive.filter(roversel => roversel.rtk_status == 5).length
            this.est = this.roverslive.filter(roversel => roversel.rtk_status == 6).length
            this.connected = this.roverslive.filter(roversel => roversel.connected == 1).length
        },

    setRTKColor(status) {
        let color = 'rgba(255, 255, 255, 0.7)'
        switch (status) {
        case 1:
            color = 'rgba(255, 96, 96, 0.7)'
            break;
        case 2:
            color = 'rgba(255, 159, 12, 0.7)'
            break;
        case 4:
            color = 'rgba(60, 210, 165, 0.7)'
            break;
        case 5:
            color = 'rgba(48, 190, 255, 0.7)'
            break;
        case 6:
            color = 'rgba(102, 102, 102, 0.7)'
            break;
        }
        return color
    },

    setExpiryColor(days) {
        if (days > 1) { return 'rgba(60, 210, 165, 0.7)' } else return 'rgba(255, 96, 96, 0.7)'
    },

    renewChoice(roverid){
        this.selectedrover = this.rovers.filter(rover=>rover.value == roverid)[0]
        this.$store.commit('setselectedrover',this.selectedrover)
        if (this.stocksubs.length != 0) {
            this.$refs['renew-choice'].show()
        } else this.menuLink('subscriptionadd') // goto buy subs
    },

    
    addRover() {
        this.$router.push({ name: 'machinenew', params: { id: 0 } }).catch(() => { })
    },

    viewRover(rover) {
        if(rover.connected == 1){
        this.$store.commit('setroversession',null)
        this.$router.push({ name: 'roverdisplay', params: { id: rover.value } }).catch(() => { })
        } else {
            this.rovertitle = rover.text
            this.selectedrover = rover
            this.$refs['rover-status'].show()
        }
    },

    editRover(rover){
        this.$store.commit('setselectedrover',rover)
        this.$router.push({ name: 'rovermanage', params: { id: rover.text } }).catch(() => { })
    },

    viewSub(id) {
        this.$router.push({ name: 'subscription', params: { id: id } }).catch(() => { })
    },

    formatDate(timedate) {
        if (timedate == 0) { return '-' }
        let date = moment(timedate * 1000).format('DD MMM y HH:mm')
        return date
    },

    menuLink(name) {
        this.$router.push({ name: name, params: {} }).catch(() => { })
    },

    closeModal(){
        this.stocksubs.forEach(element => {element.substatustext = 'Available'})
    }
    }
};
</script>