<template>
<div>
<b-container>
<b-row>
<b-col class="col-12 col-sm-6 offset-sm-3">
    
    <b-row>
        <b-col class="col-12 mt-3">
            <h4>Company Details</h4>
        </b-col>
    </b-row>
    
    <b-row class="mt-3">
        
        <b-col class="col-12 mt-3">
          <h5>{{ company.text }}</h5>
        </b-col>

        <b-col class="col-12 mt-3">
          <b-row>
            <b-col class="col-2"><b-icon-geo-alt class="icon1" /></b-col>
            <b-col class="col-10 ">
              <b-row>
                <b-col class="col-12">{{ company.address1 }}</b-col>
                <b-col class="col-12" v-if="company.address2">{{ company.address2 }}</b-col>
                <b-col class="col-12" v-if="company.address3">{{ company.address3 }}</b-col>
                <b-col class="col-12">{{ company.towncity }}</b-col>
                <b-col class="col-12">{{ company.county }}</b-col>
                <b-col class="col-12">{{ company.postcode }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="col-12">
          <b-row class="mt-3" v-if="company.mobile">
            <b-col class="col-2"><b-icon-phone class="icon1" /></b-col>
            <b-col class="col-10">{{ company.mobile }}</b-col>
          </b-row>
          <b-row class="mt-3" v-if="company.tel">
            <b-col class="col-2"><b-icon-telephone class="icon1" /></b-col>
            <b-col class="col-10">{{ company.tel }}</b-col>
          </b-row>
        </b-col>

        <b-col class="col-12 mt-3">
          <b-row>
            <b-col class="col-2"><b-icon-person class="icon1" /></b-col>
            <b-col class="col-10">
              <b-row>
                <b-col class="col-12">{{ company.fname }} {{ company.lname }}</b-col>
                <b-col class="col-12">{{ company.email }}</b-col>
                <b-col class="col-12">{{ company.password }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

    </b-row>

    <b-row class="mt-5">
        <b-col class="col-12  mt-3" v-if="user.role != 30">
          <b-button variant="outline-success" size="md" block @click="menuLink('companyedit')"><b-icon-pencil class="mr-2" />Edit
            company</b-button>
        </b-col>

        <!-- <b-col class="col-12 col-md-5 offset-md-2 mt-3">
        <b-button variant="outline-warning"  size="md"  block @click="finishCompany()"><b-icon-x-circle class="mr-2"/>Cancel</b-button>
        </b-col> -->
    </b-row>

    <b-row class="mt-3 pb-5">
        <b-col class="col-12 mt-3 mb-3">
            <h5>Company Users</h5>
        </b-col>
        
        <b-row class="col-12" v-for="user in users" :key="user.id">
           <b-col class="col-6 mt-3">
                {{  user.fname  }} {{ user.lname }}<br/>
                <span v-if="user.role==20">Company Admin</span>
                <span v-if="user.role==30">Company User</span>

            </b-col>

            <b-col class="col-6 mt-3">
         
           <b-button variant="outline-success" size="sm" @click="editUser(user.id)"><b-icon-pencil class="mr-2" />Edit
            </b-button>
            </b-col> 
        </b-row>
    </b-row>

   

   

</b-col>
</b-row>    
</b-container>
        
</div> 
</template>
    
    <script>
        export default {
            name:'CompanyDetails',
            components:{
            },
            data(){
            return {
                    company:{},
                    users:[],
                    companyadmins:[],
                   };
                    
            },
    
            created(){
    
                this.token = this.$store.getters.token
                this.user = this.$store.getters.user
                this.company = JSON.parse(JSON.stringify(this.$store.getters.company))
                this.users = this.company.users

                this.companyadmins.push({value:0,text:'Select Admin'})
                this.company.users.forEach(element => {
                    if(element.role == 20){
                    this.companyadmins.push({value:element.id,text:element.fname+' '+element.lname})
                    }
                });
                
              },
            
           methods: {

                editUser(id){
                    this.$router.push({ name: 'useredit', params: {id:id} }).catch(()=>{})
                },

                menuLink(name){
                    this.$router.push({ name: name, params: {} }).catch(()=>{})
                },

              
                finishCompany(){
                    history.back()
                },

               
             
            }	   
        
        };
</script>