<template>
<div>

<b-container>
    <b-row>
      <b-col class="col-12 text-center mt-3">
              <h3>Payment Succesful</h3>
      </b-col>

      <b-col class="col-12 text-center mt-3" >
              <h5 v-if="test">STRIPE TEST MODE</h5>
      </b-col>
    </b-row>

    <b-row>
    <b-col class="col-12 col-sm-6 offset-sm-3 mt-5">

        <b-row>
            <b-col class="col-12">
            
            <b-table 
                striped 
                responsive 
                :items="bitems" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                dark
                />
            </b-col>
        </b-row>

        <b-row class="mt-5">
            <b-col class="col-6">Subtotal</b-col>
            <b-col class="col-6">£{{ amount }}</b-col>   
        </b-row>
        <b-row v-if="couponvalue != ''">
            <b-col class="col-6">Coupon Discount</b-col>
            <b-col class="col-6">£{{ discount }}</b-col>
        </b-row>
        <b-row>
            <b-col class="col-6">VAT @20%</b-col>
            <b-col class="col-6">£{{ vat }}</b-col>   
        </b-row>
        <b-row>
            <b-col class="col-6">Total</b-col>
            <b-col class="col-6">£{{ total }}</b-col>   
        </b-row>
        
        <b-row>
            <b-col class="col-12 mt-3">
            <p>Reference : {{ reference }}</p>
            </b-col>
        </b-row>

        <b-row class="mt-5">
            <b-col class="col-12" v-if="selectedrover == ''">
                <b-btn variant="outline-success" block @click.stop="menuLink('subscriptions')">View subscriptons</b-btn>  
            </b-col>
            <b-col class="col-12" v-else>
                <b-btn variant="outline-success" block @click.stop="menuLink2('assignsubscription')">Activate subscripton</b-btn>  
            </b-col>
        </b-row>  
          
        <b-row class="mt-5">
            <b-col class="col-12">
                <img :src="stripelogo" style="width:100%">  
            </b-col>
        </b-row>   
        
         
    </b-col>
    </b-row>
    
    <SendEmail
        :sendmail="sendmail"
        :emailid="emailid"
        :emailto="emailto" 
        :companyid="company.value"
        :invoice="invoice"
        :showdiag=false
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
</b-container>

<b-modal ref="send-email" size="lg" title="Status" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" block @click="finish()">Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal> 

<LoadCompanyData :reloaddata="reloaddata" :dialog="false"/>

</div>
</template>


<script>
import LoadCompanyData from './LoadCompanyData'
import ErrorLogging from './ErrorLogging'
import SendEmail from './SendEmail'
import axios from 'axios'
import stripelogo from '@/assets/images/stripe-badge-transparent.png'
export default {
  name: 'PaymentComplete',
  components:{
    ErrorLogging,
    SendEmail,
    LoadCompanyData
  },
  data: function () {
    return {
      stripelogo,
      basket:[],
      bitems:[],
      fields:[
        { key: 'subtitle', label: 'Subscription' },
        { key: 'subquant', label: 'Quantity'},
        { key: 'subprice', label: 'Price'},
        { key: 'subtotal', label: 'Subtotal'}
      ],
      userid:0,
      user:{},
      company:{},
      amount:0,
      vat:0,
      discount:0,
      total:0,
      description:'',
      reference:'',
      invoice:{},
      couponapplied:false,
      couponvalue:'',
      token:'',
      test:false,
      reloaddata:0,
      errorresponse:{},
      stagemessages:[],
      sendmail:false,
      emailto:'',
      emailid:50, // Subscription Payment success email,
      selectedrover:{}
    }; 
},
mounted() {
    this.token = this.$store.getters.token
    this.company = this.$store.getters.company
    if(this.$store.getters.user.role == 1){this.test = true} else this.test = false
    this.selectedrover = this.$store.getters.selectedrover
    this.couponvalue = this.$store.getters.coupon
    if(this.couponvalue != ''){this.couponapplied = true}
    this.basket = this.$store.getters.basket

    this.emailto = this.company.email
    this.userid = this.$store.getters.user.id
    
    if(this.$store.getters.payment.amount > 0){
      this.bitems = this.$store.getters.payment.basket
      let total = 0
      this.bitems.forEach(element => {
          total = parseFloat(element.subtotal) + parseFloat(total)
      });
      
      if(this.coupon != ''){
                    this.discount = parseFloat(total/100 * 10).toFixed(2)
                    total = parseFloat(total) - parseFloat(this.discount)
        }

      this.amount = total.toFixed(2)  //  Basket Total from above 
      this.vat = (this.amount/100 * 20).toFixed(2) 
      this.total = parseFloat(this.amount) + parseFloat(this.vat)
      this.reference = this.$store.getters.payment.reference
      this.description = 'test'
      this.paymentComplete()
    }
 },

 beforeDestroy(){
    this.$store.commit('setbaskettotal',0)
   },


methods: {

    paymentComplete(){
          if(this.$store.getters.admindialog == true){this.$refs['send-email'].show()}
          this.$store.commit('setpaymentcomplete',true)
          this.amount = this.$store.getters.baskettotal  //  Last Paid Value
          this.vat = (this.amount/100 * 20).toFixed(2) 
          let total = parseFloat(this.amount) + parseFloat(this.vat)
          this.total = total.toFixed(2)
          this.bitems = this.$store.getters.basket
          this.createCompanyStockSubs()
    },

    
    createCompanyStockSubs(){

                 axios({
                        method: 'post',  
                        url: '/api/portalcreatestocksubscriptions',
                        headers: {"Authorization" : "Bearer " + this.token},
                        data: {
                            userid:this.userid,
                            token:this.token,
                            subs:this.basket,
                            company:this.$store.getters.companyid,
                            amount:this.amount,
                            details:this.description,
                            reference:this.reference,
                            coupon:this.$store.getters.coupon
                       
                            }
                        })
                    .then((response)=> {
                      
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        let message = {message:'Create Stock Subscriptions',text:this.errorresponse,error:true}
                        this.stagemessages.push(message)
                        } else  {
                          this.reloaddata = 4
                          // set selected sub as last created stock sub
                          this.$store.commit('setstocksubs',response.data.stocksubs)
                          this.$store.commit('setselectedsub',response.data.stocksubs[0])
                          this.stagemessages.push(...response.data.messages)
                          this.finishUpdates()
                          } 
                                        
                    })
               
                 },
                 
            
                finishUpdates(){
                            this.basket = []
                            this.$store.commit('setbasket',this.basket)
                            this.sendEmail()
                 },

                 sendEmail(){
                  this.invoice = this.$store.getters.payment
                  this.sendmail = !this.sendmail // trigger to SendEmail
                },

                 emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
                  },
                  
                  finish(){
                    this.$refs['send-email'].hide()
                  },

                  menuLink(name){
                    this.$router.push({ name: name, params: {} }).catch(()=>{})
                },

                menuLink2(name){ // Used to activate a renewed sub
                    this.$router.push({ name: name, params: {id:0} }).catch(()=>{})
                },
  },
};
</script>