<template>
<div class="borderlight">   

<b-row>
        <b-col class="col-12"> 

        <div class="leafletbuttons">
           <b-button variant="outline-success" size="sm" @click.stop="setBounds()"><b-icon-arrow-clockwise /></b-button>
           <b-button variant="outline-success" size="sm" @click.stop="toggleSat()" ><b-icon-image /></b-button>    
        </div>

        <div :id="mapcont" class="mapholder" :style="{ height: mapheight + 'px',width: mapwidth + 'px'}"></div>

        <div class="leafletrtk">
          <div class="rtkstatus" style="background-color:rgba(60, 210, 165, 0.7)">ONLINE</div>
          <div class="rtkstatus" style="background-color:rgba(255, 96, 96, 0.7)">OFFLINE</div>
       </div>
        </b-col>
       
</b-row>     

</div>
</template>


<script>

import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import L from 'leaflet'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet/dist/leaflet.css'
import { point as Point } from "leaflet"
import axios from 'axios'
  
  export default {
    name: 'BasesMap',
    props:['mapheight','mapwidth'],
      data(){
        return {
                token:'',
                bases:[],
                pointsfix:[],
                mapcont:'mapcont',
                map:{},
                currentZoom:6,
                currentCenter: [51.505, -0.09],
                showsat:false,
                satlayergroup: {},
                setbounds:true,
                };  
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        },
        
        mounted(){  

        this.token = this.$store.getters.token  
        this.markers = L.markerClusterGroup({
     
        iconCreateFunction: function(cluster) {

          let markers = cluster.getAllChildMarkers()
          let childCount = cluster.getChildCount();
          let online = 0
          let offline = 0
          let status = 0
          let values = ''

          markers.forEach(element => {
                          status = element['status']
                          switch (status){
                            case 1:
                            online++
                            break
                            case 0:
                            offline++
                            break 
                          }
          })

          let first = 0

          if(online != 0){
            online = (online/childCount * 100) * 3.6
            values += '#3cd2a5 '+first+'deg '+Number(online+first)+'deg,'
            first = Number(online+first)
            }
            
          if(offline != 0){
              offline = (offline/childCount * 100) * 3.6
              values += '#dc3545 '+first+'deg '+Number(offline+first)+'deg,'
              first = Number(offline+first)
              }
         
          values =  values.slice(0, -1)

          return L.divIcon({
                      html: "<div class='leafletcluster' style='background-image: conic-gradient("+values+");'><span class='cmarker'>"+childCount+"</span></div>",
                      className: "xpietest",
                      iconSize: new Point(40, 40)
                    });
          }
      });

      this.buildMap()
    },

        
    methods: {

      buildMap(){

          this.map = L.map(this.mapcont, {
                  center: this.currentCenter,
                  zoom: this.currentZoom,
                  doubleClickZoom: false
                // zoomAnimation:false
          }); 

          // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
          //     maxZoom: 19,
          //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          // }).addTo(this.map);

          L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
                  maxZoom: 19,
                  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              }).addTo(this.map)   
              
          this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});  
             
          this.fetchBases()
        },

        
          fetchBases(){
                axios.get('/api/basesdata',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                      userid:this.$store.getters.user.id,
                      token:this.token,
                      country:this.$store.getters.user.country
                    }
                }).then((response) => {
                    this.basestations = response.data.basestations
                    //  if(!this.timeout){this.startTimer()}
                    this.addMarkers()
                    })
                
          },

          addMarkers() {
                  let customIcon = ''
                  let color = ''
                  this.pointsfix = []
                  this.markers.clearLayers();
                  this.map.removeLayer(this.markers);
                  
                  this.basestations.forEach(element => {
                        if(element.latitude != null){
                          color = 'background-color:'+element.statuscolor
                    
                          customIcon = L.divIcon({
                            className: 'custom-icon',
                            html: '<div class="lmarkerhold" style="'+color+'"></div><div class="marker-text" style="'+color+'">'+element.text+'</div>',
                            iconSize: [80, 40], // Width and height of the icon
                            iconAnchor: [0, 0] // Center point of the icon
                          }); 
                 
                          var marker = L.marker([element.latitude, element.longitude], { icon: customIcon },{ title: element.text },{ status:element.status });
                          marker.status = element.status
                          marker.id = element.value
                          this.markers.addLayer(marker)
                          // create fit bounds array
                          this.pointsfix.push({lat:element.latitude, lng:element.longitude})
                  
                    }
          
                })  

                this.map.addLayer(this.markers)
                if(this.setbounds == true){
                  this.setbounds = false
                  this.setBounds()
                }

                if(!this.timeout){this.startTimer()}
            
              },

          setBounds(){
                if(this.pointsfix.length > 1){
                  this.map.fitBounds(this.pointsfix.map(m => { return [m['lat'], m['lng']] }),{padding: [45, 45]});
                }
          },
          
          toggleSat(){
              if(this.showsat == false) {
                this.satlayergroup.addTo(this.map);
                this.showsat = !this.showsat;
              } else {
                this.satlayergroup.remove();
                this.showsat = !this.showsat;
              } 
          },

          startTimer(){
                this.timeout = setInterval(() => { this.fetchBases() }, 30000)
          },
        }
    };
</script>