<template>
  <div>
    <b-container>

      <b-row class="mb-3">

        <b-col class="col-12 mt-3">

          <label>Model</label>
          <b-form-input name="Model" type="search" placeholder="required" autocomplete="off" v-model="model.text"
            :disabled="lockfield" v-validate="'required|alreadyExists|min:3|max:32'" />
          <div class="text-danger">{{ errors.first('Model') }}</div>


        </b-col>


      </b-row>

      <b-row class="mt-5 pt-3 border-top">
        <b-col class="col-12">
          <b-button variant="success" @click="updateModel()" block>{{saveButtonText}}</b-button>
        </b-col>

        <b-col class="col-12 mt-3" v-if="model.value !== 0">
          <b-button variant="danger" block @click="deleteModel(model.value)">DELETE</b-button>
        </b-col>
      </b-row>


    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>


<script>
import { Validator } from 'vee-validate';
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {

  props: ['id', 'maker', 'models'],
  components: {ErrorLogging}, 
  data() {
    return {
      token: '',
      model: {},
      searchtext: '',
      lockfield: false,
      errorresponse: {},
      saveButtonText: 'Save',
    };

  },

  created() {

    this.token = this.$store.getters.token
    if (this.id == 0) { this.newModel() } else this.fetchModel()


  },
  mounted() {
    this.addAlreadyExistsRule();
  },
  methods: {

    addAlreadyExistsRule() {
      //adding a rule to vee validator to check if the model already exists
      Validator.extend('alreadyExists', {
        getMessage: "Model already exists.",
        validate: (value) => {
          const modelText = this.models.map(modelObj => modelObj.text.toLowerCase());
          if (modelText.includes(value.toLowerCase())) return false;
          return true;
        }
      })
    },


    fetchModel() {
      axios.get('/api/machinemodels', {
        headers: { "Authorization": "Bearer " + this.$store.getters.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
            return;
          } 

          this.model = response.data.data
        })
        .catch(err => {
          this.errorresponse = err;
        });
    },


    newModel() {

      this.model.value = 0
      this.model.text = ''
      this.model.maker = this.maker

    },


    updateModel() {
      if (this.lockfield) return;
      this.lockfield = true;

      this.$validator.validateAll().then(result => {
        if (!result) {
          this.lockfield = false;
          return;
        }

        //JM - This If side i dont think is accessible anymore, I cant find an edit model.
        if (this.model.value !== 0) {
          axios({
            method: 'put',
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/machinemodel',
            data:
            {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
              modeldata: this.model
            }

          })
            .then((response) => {

              if (response.data?.code == 999 || response.data.error) {
                this.errorresponse = response.data;
                return;
              } 

              this.saveButtonText = 'Model Updated';
              setInterval( () => {
                this.$emit('updatemodels', response.data);
              },2000);
       
            })
            .catch(err => {
              this.errorresponse = err;
            }).finally(() => {
              this.lockfield = false;
            });
        } else {


          axios({
            method: 'post',     //   New Machine
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/machinemodel',
            data:
            {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
              modeldata: this.model
            }
          })
            .then((response) => {

              if (response.data?.code == 999 || response.data.error) {
                this.errorresponse = response.data;
                return;
              } 

              this.saveButtonText = 'Model Added';
              setInterval( () => {
                this.$emit('updatemodels', response.data);
              },2000);
       
            })
            .catch(err => {
              this.errorresponse = err;
            })
            .finally(() => {
              this.lockfield = false;
            });

        }

      })

    },

    //JM - This also seems to be inacessible right now.  Interface relies on swal so im just going to comment it
    //out for now, if we want to put it back in we need to make a non swal interface.
    deleteModel(id) {
      /*
      this.$swal({
        title: "Delete?",
        text: "This will remove this model",
        icon: "question",
        showCloseButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes',
        showCancelButton: true,
        cancelButtonText: 'No',
      })
        .then(() => {

          axios({
            method: 'delete',
            headers: { "Authorization": "Bearer " + this.token },
            url: '/api/machinemodel/' + id,
            data: {
              userid: this.$store.getters.user.id,
              token: this.$store.getters.user.token,
            }
          })
            .then(
              this.$emit('updatemodal')
            )
            .catch(err => {
              this.$swal({
                title: "Update Error",
                text: err,
                icon: "error",
                showCloseButton: true,
                showConfirmButton: false
              })
            });

        })
      */
        console.error('deleteModel, This function was thought not implemented needs to be renabled',id);
        this.closemodal();
    },

    closemodal() {
      this.$emit('closemodal')
    },

  }

};

</script>
