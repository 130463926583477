<template>
  <div class="borderlight boxclickable" @click="menuLink('RoverSessionHistory')"
    style="color: rgba(255, 255, 255, 0.7);">
    
    <b-container>

      <!--Header-->
      <b-row class="mt-2 mb-2">
          <b-col>REPORTS</b-col>
      </b-row>

      <!--Info-->
      <b-row>
        <!--Last Connections-->
        <b-col class="col-12 col-md-6" order-md="1">
          <h2 class="m-0" style="font-weight:bold">
            <span style="font-size:0.3em; vertical-align:center">LAST</span> <span
              style="font-size:0.6em; color:rgba(255,255,255,1)">5</span> <span
              style="font-size:0.3em; vertical-align:center">CONNECTIONS</span>
          </h2>
        </b-col>

        <!--Chart-->
        <b-col class="col-12" order-md="3">
          <BarChartStacked :chartdata="data" v-if="display" :height="200" />
        </b-col>

        <!--Consumption-->
        <b-col order-md="2" class="col-12 col-md-6">
          <h4 style="font-size:0.8em; vertical-align:center">CONSUMPTION<br />{{ total }}</h4>
        </b-col>
      </b-row>

    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>


<script>
import BarChartStacked from './BarChartStacked'
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['id'],
  name: 'CasterRoverLast5Card',
  components: {
    BarChartStacked,
    ErrorLogging
  },
  data() {
    return {
      token: '',
      data: [],
      total: '-',
      display: false,
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.fetchRovers()
  },

  methods: {

    fetchRovers() {

      axios.get('/api/lastfiveconnections', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          green: 0,
          rover: this.id
        }
      })
        .then((response) => {
          if(response.data == 'NOT AUTHORISED'){
            this.$router.push({ name: 'logout', params: {} }).catch(() => { })
          }
          
          if (response.data.code == 999) {
            this.errorresponse = response.data;
          } else {
            this.data = response.data
            this.total = this.convertTime(response.data.totaltime)
            if (this.data.datasets[0].data !== undefined) { this.display = true }

          }
        }).catch((err) => {
          this.errorresponse = err;
        });
    },

    convertTime(d) {
      let h = Math.floor(d / 3600);
      let m = Math.floor(d % 3600 / 60);
      let s = Math.floor(d % 3600 % 60);

      let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      return hDisplay + mDisplay + sDisplay;
    },
    menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },
  },
};
</script>