<template>
<div>
        <b-input-group> 
                        <b-input-group-prepend is-text>
                            <b-icon-search font-scale="0.8" style="color:black" />
                        </b-input-group-prepend>

                    <b-form-input 
                            id="darksearch"
                            type="search" 
                            size="sm"
                            v-model="searchtext" 
                            @keyup="searchDealer()"
                            @input="clearSearch()"
                            autocomplete="off" 
                            placeholder=""
                            class="darksearch" 
                        />

        </b-input-group>        

</div> 
</template>

<script>
	export default {
        name: 'SearchBox',
		data(){
		return {
            searchtext:'',
            };
        },
		created(){
           
		},
	
		methods: {

		
            searchDealer(){
                this.$emit('textsearch',this.searchtext)
            },

            clearSearch(){
                if(this.searchtext == ''){
                    this.$emit('clearsearch')
                }    
            },
        }
	
};

</script> 