<template>
<div>

<b-row>
<b-col class="col-12 col-sm-6 offset-sm-3">
   
<b-row class="mt-3">
        <b-col class="col-12">
            <h4>Edit Subscription</h4>
         </b-col>
</b-row>

<b-row class="mt-3">
        <b-col class="col-12">
            <h5>{{  rover.text }}</h5>
            <h5>{{  rover.machine[0].text }}</h5>
        </b-col>
</b-row>

<b-row class="mt-2">

    <b-col class="col-5 mt-4">
        <label>NTRIP Username</label>
    </b-col>

    <b-col class="col-7 mt-3">
            <b-form-input 
                type="search" 
                placeholder="" 
                autocomplete="off"
                v-model="rover.username"
                class="lightinput"
                name="rover.username"
                v-validate="{required:true}"
                :class="{'b-form-select': true, 'is-danger': errors.has('expire') }"
                :disabled="updated"
                />
    </b-col>

    <b-col class="col-5 mt-4">
        <label>NTRIP Password</label>
    </b-col>
    
    <b-col class="col-7 mt-3">
            <b-form-input 
                type="search" 
                placeholder="password" 
                autocomplete="off"
                v-model="rover.password"
                class="lightinput"
                name="rover.password"
                v-validate="{required:true}"
                :class="{'b-form-select': true, 'is-danger': errors.has('expire') }"
                :disabled="updated"
                />
    </b-col> 

    <b-col class="col-5 mt-4">
        <label>Connection IP/URL</label>
    </b-col>

    <b-col class="col-7 mt-3">        
            <b-form-input 
                type="search" 
                placeholder="" 
                autocomplete="off"
                v-model="rover.connection"
                class="lightinput"
                disabled
                />
    </b-col>

     <b-col class="col-5 mt-4">
        <label>Connection Port</label>
     </b-col>
    
     <b-col class="col-7 mt-3">
        <b-form-input 
            type="search" 
            placeholder="" 
            autocomplete="off"
            v-model="rover.port"
            class="lightinput"
            disabled
            />
    </b-col>

    <b-col class="col-5 mt-4">
        <label>Mount Point</label>
     </b-col>
    
     <b-col class="col-7 mt-3">
        <b-form-input 
            type="search" 
            placeholder="" 
            autocomplete="off"
            v-model="rover.mount"
            class="lightinput"
            disabled
            />
    </b-col>

  </b-row>

  <b-row>
   
    <b-col class="col-12 col-sm-6 mt-5" >
        <b-button variant="outline-info" block size="sm" @click.stop="toggleAdvanced()">show advanced settings</b-button>
    </b-col>
       
    <b-col class="col-12 mt-3" v-if="showadvanced">
            <b-form-checkbox
            v-model="rover.needs_gga"
            size="lg"
            value=1
            inline
            unchecked-value=0
            />
            <label>Needs GGA</label>
    </b-col>

 
     <b-col class="col-12 mt-3" v-if="showadvanced">
            <b-form-checkbox
            v-model="rover.disconnect_onchangebase"
            size="lg"
            inline
            value=1
            unchecked-value=0
            />
            <label>Disconnect base change</label>
    </b-col>  
   
</b-row>

<b-row class="pb-5 mt-5" v-if="!updated">
        <b-col class="col-12 col-sm-5 mt-3">
            <b-button variant="outline-success" @click.stop="validateData()" block><b-icon-check-circle class="mr-2" />Update</b-button>
       </b-col>  
       <b-col class="col-12 col-sm-5 offset-sm-2 mt-3">
            <b-button variant="outline-warning" @click.stop="cancel()" block><b-icon-x-circle class="mr-2" />Cancel</b-button>
       </b-col>  
</b-row> 

<b-row class="pb-5 mt-5" v-else>
        <b-col class="col-12  mt-3">
            <b-button variant="outline-success" @click.stop="cancel()" block><b-icon-check-circle class="mr-2" />Subscription updated</b-button>
       </b-col> 
</b-row>       

</b-col>
</b-row>
   

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
<LoadCompanyData :reloaddata="reloaddata"/>

</div> 
</template>

<script>
    import ErrorLogging from './ErrorLogging'
    import LoadCompanyData from './LoadCompanyData'
    import moment from 'moment'
    import axios from 'axios'
    export default {
        name:'SubscriptionEdit',
        components:{
            ErrorLogging,
            LoadCompanyData
        },
        data(){
        return {
                token:'',
                sub:{},
                rover:{},
                iccid:'',
                showadvanced:false,
                errorresponse:{},
                reloaddata:0,
                updated:false,
                };
                
      },
      
      created(){
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            // Get stored rover value and decouple object
            this.rover = JSON.parse(JSON.stringify(this.$store.getters.rovers)).filter(roversel => roversel.value == this.$route.params.id)[0]
            this.rover.mount = 'RTK_RTCM3'
            if(this.rover.simm.length > 0){this.iccid = this.rover.simm[0].text}
            if(this.rover.needs_gga == 'Yes'){ this.rover.needs_gga = 1 } else this.rover.needs_gga = 0
            if(this.rover.disconnect_onchangebase == 'Yes'){ this.rover.disconnect_onchangebase = 1 } else this.rover.disconnect_onchangebase = 0
            },

       methods: {

            validateData(){
                this.$validator.validateAll().then(result => {
                        if (result) { this.updateSub()}
                });        
             },

            formatDate(date){
                return moment(date).format('MMM Do YYYY, h:mm a')
            },

            updateSub(){  //  Update NTRIP values 

                axios({
                    method: 'post',  
                        url: '/api/portalupdatentrip',
                        headers: {"Authorization" : "Bearer " + this.token},
                        data: {
                            userid:this.userid, 
                            token:this.token,
                            username:this.rover.username,
                            password:this.rover.password,
                            needsGGA:this.rover.needs_gga,
                            changebase:this.rover.disconnect_onchangebase,
                            rover:this.rover.value,
                            company:this.rover.company
                            }
                    })
                    .then((response)=> {
                            if(response.data.code == 999){
                                    //this.errorresponse = response.data
                                    console.log(response.data)
                                    alert('Exception')
                                    this.saving = false
                                } else {
                                    this.updated = true
                                    this.reloaddata = 2 // Trigger reload of rovers
                                }
                        
                            });	
            },

            toggleAdvanced(){
                this.showadvanced = !this.showadvanced
            },
          
            cancel(){
                  history.back()
            },

		}	   
};
</script>