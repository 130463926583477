<template>
  <div>
    <span v-if="test">
      <h5>USE TEST CARD 4242 4242 4242 4242 06/29 123</h5>
    </span>
    <div v-if="showpayment" style="border: 1px solid rgba(255,255,255,0.2); padding: 20px;display: block;">
      <h6 style="margin-bottom:10px">PAYMENT METHOD</h6>
      <div class="processing" v-if="processing"><img :src="spin"></div>
      <form id="payment-form">
        <div id="card-element"></div>
        <button id="submit" :disabled="formerrors" class="brochurebutton" style="margin-top:30px;width:100%">
          <span v-if="!processing" id="button-text">Pay now £{{ totalincvat }}</span>
          <span v-else id="spinner">Processing payment</span>
        </button>

      </form>
    </div>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import spin from '@/assets/images/process.gif'
import ErrorLogging from './ErrorLogging.vue';

export default {
  name: 'PaymentStripeEmbed',
  props: ['formerrors'],
  components: {
    ErrorLogging,
  },
  data: function () {
    return {
      test: false,
      total: 0,
      spin,
      paymentintent: {},
      totalincvat: 0,
      displayCardHolder: true,
      processing: false,
      showpayment: true,
      description: '',
      basket: [],
      testkey: 'pk_test_51MvjMpDz8RVM6NsFMEM3BpGmM7K6NY6blxwl2IwinJLjEvi8kuhB6dsTlZDdR9fBfaFRZRRxrnZJzT9zQTDSCXog00Hx6L0PNT',
      livekey: 'pk_live_51MvjMpDz8RVM6NsFnLW55Q8v0shJsk2XwsBaIzeKLXi2WEep3lO8Jhwxddxj3SeJYapgTkYVfLUAfTvIuwmGIe3X00A6g6ByAx',
      key:'',
      token: '',
      errorresponse: {},
    };
  },
  mounted() {
    if (this.$store.getters.user.role == 1) { this.test = true } else this.test = false
    
    this.$store.commit('setpaymentcomplete', false)
    this.token = this.$store.getters.token
    this.company = this.$store.getters.company
    this.setTotals()
  },

  computed: mapState(['baskettotal', 'coupon', 'paymentcomplete']),

  watch: {

    baskettotal() {
      this.setTotals()

    },

  },

  methods: {

    setTotals() {
      this.basket = this.$store.getters.basket
      this.total = this.baskettotal

      this.vat = (this.total / 100 * 20).toFixed(2)
      this.totalincvat = this.setTotalIncVAT(this.total, this.vat)

      if (this.totalincvat > 0) {
        this.paymentIntentGet()
      }
    },

    setTotalIncVAT(total, vat) {
      return (parseFloat(total) + parseFloat(vat)).toFixed(2)
    },

    async paymentIntentGet() {

      let self = this
      let stripe = {}
      self.description = ''
      self.basket.forEach(element => {
        self.description = self.description + ' ' + element.subquant + ' ' + element.subtitle
      });
      self.description = self.description + ' ' + this.coupon

      document.querySelector("#payment-form").addEventListener("submit", handleSubmit)

      if (this.test == true) { // RODTEST
        stripe = await loadStripe(this.testkey)
      } else stripe = await loadStripe(this.livekey) 

      const intent = await
        axios.get('/api/portalstripeintent', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.token,
            amount: Math.round(this.totalincvat * 100),
            description: this.description,
            receipt_email: this.company.email,
            key: this.publickey,
            test: this.test
          }
        })
        .then((response) => {
          if (response?.data?.code == 999) {
            this.errorresponse = response.data
            return;
          }
          return response.data
        })
        .catch(err => {
          this.errorresponse = err;
        });

      const appearance = {
        theme: 'night',
      };

      let clientSecret = intent
      this.$store.commit('setpaymentintent', intent)

      const elements = stripe.elements({ appearance, clientSecret })

      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = elements.create("payment", paymentElementOptions)


      paymentElement.mount("#card-element")



      // Show a spinner on payment submission
      function setLoading(isLoading) {
        self.processing = isLoading

        // if (isLoading) {
        //   // Disable the button and show a spinner
        //   document.querySelector("#submit").disabled = true;
        // } else {
        //   document.querySelector("#submit").disabled = false;
        // }
      }

      async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let response = await stripe.confirmPayment({
          elements,
          confirmParams: {},
          redirect: 'if_required'
        })
        self.processing = false

        if (response.error) {
          setLoading(false)
          self.errorresponse = response.error;
        } else {
          let data = {}
          data.amount = (response.paymentIntent.amount / 100).toFixed(2)
          data.description = self.description
          data.reference = response.paymentIntent.payment_method
          data.paymentintent = clientSecret
          self.$emit('paymentcomplete', data)
        }

      }


    },

  },
};
</script>
