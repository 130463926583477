<template>
  <div>
    <b-container>

      <b-row v-if="!edit && !deleteuser">

        <b-col class="col-12 mt-1 mb-3">
          <b-row class="mt-3">
            <b-col class="col-2"><b-icon-person class="icon1" /></b-col>
            <b-col class="col-10">{{ user.roletitle }}<span v-if="primaryadmin"> ( Main Contact )</span></b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="col-2"><b-icon-envelope class="icon1" /></b-col>
            <b-col class="col-10">{{ user.email }}</b-col>
          </b-row>
          <b-row>
            <b-col class="col-12">
              <b-row class="mt-3" v-if="user.mobile">
                <b-col class="col-2"><b-icon-phone class="icon1" /></b-col>
                <b-col class="col-10">{{ user.mobile }}</b-col>
              </b-row>
              <b-row class="mt-3" v-if="user.phone">
                <b-col class="col-2"><b-icon-telephone class="icon1" /></b-col>
                <b-col class="col-10">{{ user.phone }}</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <b-row v-if="user.role !== 1">
        <b-col class="col-12 col-md-5 mt-3">
          <b-button variant="outline-success" size="sm" block @click="editProfile()"><b-icon-pencil class="mr-2" />Edit
            User</b-button>
        </b-col>

        <b-col class="col-12 col-md-5 offset-md-2 mt-3">
          <b-button variant="outline-danger" size="sm" block @click="deleteProfile()"><b-icon-x-circle
              class="mr-2" />Delete User</b-button>
        </b-col>
      </b-row>

      <!-- <b-row v-if="edit && !deleteuser">
        <CasterUserEdit 
            :userid="user.id"
            :primary="primaryadmin"
            v-on:closemodal=closemodal()
            v-on:updatemodal=updatemodal() 
        />
       </b-row>

       <b-row v-if="deleteuser" >
        <CasterUserDelete
            :passuser="user"
            v-on:closemodal=closemodal2()
            v-on:updatemodal=updatemodal() 
        />
       </b-row>  
     -->

    </b-container>
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>


<script>
// import CasterUserEdit from './CasterUserEdit'
// import CasterUserDelete from './CasterUserDelete'
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['passuser'],
  name: 'UserProfile',
  components: {
    // CasterUserEdit,
    // CasterUserDelete
    ErrorLogging,
  },
  data() {
    return {
      edit: false,
      deleteuser: false,
      token: '',
      user: {},
      usersel: {},
      company: {},
      primaryadmin: 0,
      currentemail: '',
      emailerror: false,
      emailerror2: false,
      roles: [
        { value: 0, text: 'Select role' },
        { value: 20, text: 'Company Admin' },
        { value: 30, text: 'Company User' },
      ],
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    if (this.passuser == undefined) { this.user = this.$store.getters.user } else this.user = this.passuser
    this.company = this.$store.getters.company
    this.currentemail = this.user.email
    this.user.changepassword = ''
    if (this.user.id == this.company.adminuser) { this.primaryadmin = 1 } else this.primaryadmin = 0

    switch (this.user.role) {
      case 1:
        this.user.roletitle = 'System Admin'
        break
      case 20:
        this.user.roletitle = 'Company Admin'
        break
      case 30:
        this.user.roletitle = 'Company User'
        break
    }

  },

  methods: {

    editProfile() {
      this.edit = true
    },

    deleteProfile() {
      this.deleteuser = true
    },

    validateAll() {
      if (!this.isValidEmail(this.user.email)) {
        this.emailerror2 = true
        return
      } else this.emailerror2 = false

      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.user.changepassword !== '') { this.user.password = this.user.changepassword }
        }
      })
    },

    validateField(field) {
      setTimeout(() => this.validateDebounced(field), 300)
    },

    validateDebounced(field) {
      this.$validator.validate(field)
      if (field == 'user.email') { this.emailCheck() }
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    emailCheck() {
      if (this.currentemail !== this.user.email) {

        axios.get('/api/checkuserexists', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.token,
            email: this.user.email
          }
        })
          .then((response) => {

            if (response.data.code == 999) {
              this.errorresponse = response.data
            } else {

              if (response.data.exists == true) {
                this.emailerror = true
              } else {
                this.emailerror = false //this.$emit('dealer',this.dealer)
              }
            }

          })
          .catch((err) => {
            this.errorresponse = err;
          });
          
      }
    },

    createPassword() {
      let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
      let pwdLen = 8
      this.user.changepassword = Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('')
    },

    cancelUserProfile() {
      this.edit = false
    },

    updateUserProfile() {
      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/portaluserprofile',
        data: {
          userid: this.$store.getters.user.id,
          token: this.token,
          user: this.user,
          company: this.company.value,
          companydetails: this.company
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.saving = false
            this.errorresponse = response.data;
          } else {
            this.saving = false
            this.$emit('updatemodal')
          }

        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    updatemodal() {
      this.$emit('updatemodal')
    },

    closemodal() {
      this.$emit('closemodal')
    },

    closemodal2() {
      this.deleteuser = false
    },

  }

};
</script>