<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="col-12">
          <h5>System Admin Select Company</h5>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 mt-3">
              <b-form-checkbox 
                inline 
                v-model="admin_dialog" 
                size="lg" 
                :value="true" 
                :unchecked-value="false"
                />
              <label style="margin-right:15px">Show admin dialogs </label>
            </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 col-sm-6 mt-3">
          <SearchBox v-on:textsearch="filterText" />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 col-sm-6 mt-3">
          <b-table striped responsive hover :items="companys" :fields="fields" tbody-tr-class="rowClass"
            thead-tr-class="rowHClass" @row-clicked="selectCompany($event)" dark></b-table>
        </b-col>
      </b-row>

      <LoadCompanyData />

    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import SearchBox from './SearchBox'
import LoadCompanyData from './LoadCompanyData'
import axios from 'axios'
import moment from 'moment'
import ErrorLogging from './ErrorLogging.vue'

export default {
  name: 'AdminSelectCompany',
  components: {
    SearchBox,
    LoadCompanyData,
    ErrorLogging
  },
  data() {
    return {
      token: '',
      userid: 0,
      admin_dialog:false,
      companys: [],
      list: 0,
      searchtext: '',
      fields: [
        { key: 'text', label: 'Company' }
      ],
      companysel:{},
      errorresponse: {},
    };
  },


  created() {
    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.admin_dialog = this.$store.getters.admindialog
    this.fetchCompanys()
  },


  methods: {

    fetchCompanys() {
      axios.get('/api/portalcompany', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.userid,
          token: this.token,
          stext: this.searchtext,
          list: 1
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.companys = response.data.company
          }
        })
        .catch(err => {
          this.errorresponse = err;
        });
    },

    filterText(text) {
      this.searchtext = text
      this.fetchCompanys()
    },

    selectCompany(event) {
      this.companysel = event.value
      this.$store.commit('setadmindialog',this.admin_dialog)
      this.$store.commit('setcompanyid', 0) 
      this.timeout = setTimeout(this.setSelectedCompany,500)
    },

    setSelectedCompany(){
      this.$store.commit('setcompanyid', this.companysel)
    },

    async waitfetchCompanyData(name) {
      await this.fetchCompanyData(name)
    },

    async fetchCompanyData(id) {

      return new Promise((resolve) => {


        // Get Company Rovers
        axios.get('/api/companyroverstest', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.userid,
            token: this.token,
            company: id,
            full: 1
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
            
          } else this.$store.commit('setrovers', response.data.rovers)

        }).catch(err => {
          this.errorresponse = err;
        });

        // Get Stock subs 

        axios.get('/api/castersubscriptionsstock', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.userid,
            token: this.token,
            stock: 1,
            company: id
          }
        }).then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else this.$store.commit('setstocksubs', response.data.CasterSubs)

        }).catch(err => {
          this.errorresponse = err;
        });

        // Get current subscriptions 

        axios.get('/api/casterntripsubscriptions', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.userid,
            token: this.token,
            //status:this.status,
            company: id,
            portal: true
          }
        }).then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {

            let subs = response.data.CasterSubs
            this.$store.commit('setsubs', subs) // Set in store

            // Clear roversdata
            //this.$store.commit('setrovers',[])
            resolve(true)

          }

        }).catch(err => {
          this.errorresponse = err;
        });
      })

    },

    formatDate(timedate) {
      if (timedate == 0) { return '-' }
      let date = moment(timedate * 1000).format('DD-MM-YY HH:mm')
      return date
    },


    menuLink(name) {
      this.$router.push({ name: name, params: {} }).catch(() => { })
    }
  }

};

</script>
