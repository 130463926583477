<template>
<div>

<b-row>
<b-col class="col-12 col-sm-6 offset-sm-3">
    <b-row>
          <b-col class="col-6">
            <h4>Rover</h4>
          </b-col>
          <b-col class="col-6 text-right">
            <b-button  :style="{ backgroundColor:setRTKColor() }" block size="sm" >{{ statustext }}</b-button>
          </b-col>
    </b-row>

    <b-row>
          <b-col class="col-12">
            <h5>{{ rover.text }}</h5>
            <h6 v-if="rover.machine !== null">{{ rover.machine[0].text }}</h6>
          </b-col>
    </b-row>
    
    <b-row class="mt-0 pb-5" v-if="rover.connected == 0">
     
        <b-col class="col-12 mt-3 p-5 text-center">
            <b-button variant="danger" block disabled>ROVER NOT CONNECTED</b-button>
        </b-col>
    
        <b-col class="col-12 col-lg-6 mt-3">
            <CasterRoverLast5Card  :id="roverid" />
        </b-col>
    
    </b-row>  
    
    <b-row class="mt-0 pb-5" v-if="rover.connected == 1 && mapshow">
       
        <b-col class="col-12 mt-1 p-2">
            <SessionStatusRTKCard :stats='rtkstats'/>
        </b-col>

        <b-col class="col-12 mt-0 p-2">
            <CasterRoverStatusCard :rover='roverlive'/>
        </b-col>

        <b-col class="col-12 mt-0 p-2" v-if="mapshow" >
            <MapLeafletRoverSession :mapdata="currentsession" :border="true" />
        </b-col>

        <b-col class="col-12 p-2" v-if="mapshow">
            <b-button size="md" variant="outline-success" block @click="showHistory()">
                <b-icon-clock-history class="mr-2" />VIEW SESSION HISTORY
            </b-button>
        </b-col>
     
        <b-col class="col-12 mt-1 p-2" style="font-size:0.9em;color: rgba(255, 255, 255, 0.7)">
            <RoverLastEventsCard :logs="currentsession.logs" />
        </b-col> 
        
        <b-col class="col-12 mt-2 p-2">
            <SessionStatusCard :sessiondata="sessiondata" />
        </b-col>

        <b-col class="col-12 mt-1 p-2">
            <CasterRoverStatusCard3  :rover="roverlive" />
        </b-col>
        
        <b-col class="col-12 mt-1 p-2">
            <CasterRoverLast5Card  :id="roverid" />
        </b-col>
    
    </b-row>

    <b-row v-if="rover.connected == 1 && !mapshow">
        <b-col class="col-12 mt-5 p-2 text-center" style="color:orange">
                <b-spinner class="align-middle" style="margin-right:10px;font-size:0.7em"></b-spinner> LOADING ROVER DATA
        </b-col>
    </b-row>

 </b-col>
 </b-row>   
   
 <LoadCompanyData :reloaddata="reloaddata" :dialog="false" />

</div> 
</template>
    
<script>
import LoadCompanyData from './LoadCompanyData'
import RoverLastEventsCard from './RoverLastEventsCard'
import MapLeafletRoverSession from './MapLeafletRoverSession'
import CasterRoverStatusCard from './CasterRoverStatusCard'
import CasterRoverStatusCard3 from './CasterRoverStatusCard3'
import SessionStatusCard from './SessionStatusCard'
import CasterRoverLast5Card from './CasterRoverLast5Card'
import SessionStatusRTKCard from './SessionStatusRTKCard'
import { mapState } from 'vuex'
export default {
    name:'RoverDisplay',
    components:{
        LoadCompanyData,
        MapLeafletRoverSession,
        RoverLastEventsCard,
        CasterRoverStatusCard,
        SessionStatusCard,
        CasterRoverStatusCard3,
        CasterRoverLast5Card,
        SessionStatusRTKCard
    }, 
    data(){
        return {
            roverid:0,
            currentsession:{},
            sessiondata:{
                basestation:'-',
                quality:0,
                time_to_fix:0,
                ggas:0
            },
            rover:{},
            roverlive:{},
            rtkstats:{},
            connected:false,
            status:0,
            statustext:'',
            mapshow:false,
            showmap:false,
            reloaddata:0,
            timeout:false,
            };
            
    },

    beforeDestroy () {
        clearInterval(this.timeout)
    },          

    created(){
        this.roverid = this.$route.params.id
        this.$store.commit('setroverid',this.roverid)
        this.rover = this.$store.getters.rovers.filter(roversel => roversel.value == this.roverid)[0]
        this.status = this.rover.rtk_status
        this.statustext = this.rover.statustext
        this.timeout = setInterval(this.getRoverdata,5000)

        //RB add
        if(this.$store.getters.roversession !== null){
            this.currentsession = this.$store.getters.roversession
            this.sessiondata = this.$store.getters.roversession.session[0]
            this.loadSession()
        } else this.mapshow = false   

    },

    mounted(){
        this.getRoverdata()
    },

    computed: mapState(['roversession']),

    watch: {
    roversession: function() {
        if(this.roversession.session !== null){
            this.currentsession = this.roversession
            this.sessiondata = this.roversession.session[0]
            this.loadSession()
            
        } else {
            console.log('No live session data')
            this.connected = false
            this.mapshow = false
        }
       
        }
    },

methods: {

    getRoverdata(){
            this.reloaddata = 5
            setTimeout(() => {this.reloaddata = 0}, 2000);
    },

    loadSession(){
            this.roverlive = this.roversession.session[0].rover[0]
            this.roverlive.bytessent = this.sessiondata.bytessent
            this.roverlive.bytesrcvd = this.sessiondata.bytesrcvd
            this.sessiondata.num_sats = this.roverlive.num_sats
            this.status = this.roverlive.rtk_status
            this.statustext = this.roverlive.rtk_message
            this.rtkstats = this.roversession.rtkstats
            this.connected = true
            this.mapshow = true
    },
   
    showMap(){
        this.showmap = true
    },

    showHistory(){
        this.$router.push({ name: 'RoverSessionHistory', params: {id:this.roverid} }).catch(()=>{})
    },

    setRTKColor(){
            let color = 'rgba(255, 255, 255, 0.7)'
            switch(this.status){
                case 1:
                color='rgba(255, 96, 96, 0.7)'
                break;
                case 2:
                color='rgba(255, 159, 12, 0.7)'
                break;
                case 4:
                color='rgba(60, 210, 165, 0.7)'
                break;
                case 5:
                color='rgba(48, 190, 255, 0.7)'
                break;
                case 6:
                color='rgba(102, 102, 102, 0.7)'
                break;
            }
            return color
        },
 
}	       
};
</script>