<script>

import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      token: '',
      total: 0,

      cdata: {
        labels: [],
        datasets: [],
      },

      chartoptions: {

        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              fontColor: "#FFF",
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'session id',
              fontColor: "#999"
            },
            gridLines: {
              display: true
            },

            
          }],
          xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: '% RTK state',
              fontColor: "#999"
            },
            gridLines: {
              display: true
            },
          }]
        },
        legend: {
          display: false
        },

        responsive: true,
        maintainAspectRatio: false,
        maxBarThickness:100,
        categoryPercentage: 1, // Adjust this to increase/decrease space between bars
        barPercentage: 0.75, // Adjust this to control bar thickness

      },


    };

  },

  mounted() {
    this.token = this.$store.getters.token
    this.buildChart()
  },

  methods: {

    buildChart() {
      let dsoptions = {
        label: '',
        data: [],
      }

      let ds2 = JSON.stringify(dsoptions)
      this.cdata.labels = this.chartdata.labels
      
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        let dataset = JSON.parse(ds2)
        dataset.label = this.chartdata.datasets[i].label
        dataset.backgroundColor = this.chartdata.datasets[i].color
        dataset.data = this.chartdata.datasets[i].data
        this.cdata.datasets.push(dataset)
      }

      this.renderChart(this.cdata, this.chartoptions)
    },

  },
}

</script>