<template>
<div class="loginpage">
<NavBarBlank  />
<b-container>
    <b-row class="vh-100" align-v="center">
      <b-col class="col-12 col-sm-8 offset-sm-2 col-md-4 offset-md-4 p-3 loginbox">
        <PortalLogin v-on:loggedin="loginOk"/>
      </b-col>
   </b-row> 

<div class="fluid-container footer">
    <p class="text-center">Cloudbase Positioning Ltd &;copy 2024</p>
</div>

<LoadCompanyData />
</b-container>

</div>
</template>

<script>
import NavBarBlank from './NavBarBlank'
import PortalLogin from './PortalLogin'
import LoadCompanyData from './LoadCompanyData'
export default {
  name: 'UserLogin',
  components:{
    NavBarBlank,
    PortalLogin,
    LoadCompanyData
  },
 
  methods:{

    loginOk(){
        // If a sysadmin admin go to company select page otherwise set companyid to user companyid to trigger LoadCompanyData for passed company
        if(this.$store.getters.user.role == 1){
          this.$router.push({ name: 'adminselectcompany', params: {} }).catch(()=>{})
        } else this.$store.commit('setcompanyid',this.$store.getters.user.company)
          
    },
 
  }            
}
</script>