<template>
  <div>

    <b-row v-if="display">

      <b-col class="col-12 col-sm-6 offset-sm-3">

        <b-row class="mt-3">
          <b-col class="col-12" v-if="!subrenew">
            <h4>Assign Subscription</h4>
          </b-col>

          <b-col class="col-12" v-else>
            <h4>Activate Subscription</h4>
          </b-col>

         <b-col class="col-12">  
            <h5>{{ rover.text }}</h5>
            <h5>{{ rover.machine[0].text }}</h5>
          </b-col>
        </b-row>

        <b-row class="mt-3" >

          <b-col class="col-12 mt-3">
            <label>Subscription</label>
            <b-form-select 
              v-model="subsel" 
              :options="subtypes" 
              name="subsel" 
              class="darkinput" 
              @change="setSub($event)"
              v-validate="{ min_value: 1 }" 
              :class="{ 'b-form-select': true, 'is-danger': errors.has('subsel') }"
              :disabled="sub.status == 6 || sub.status == 3 || subrenew" 
              />

            <span style="color:red" v-show="errors.has('subsel')">** required</span>
          </b-col>

          </b-row>

          <b-row class="mt-3" v-if="!subrenew">

          <b-col class="col-12 mt-3" v-if="!nosimm && simms.length > 0">
            <label>SIM Card</label>
            <b-form-select v-model="simsel" :options="simms" @change="setSimm()" class="darkinput" name="simsel"
              v-validate="{ min_value: 1 }" :class="{ 'b-form-select': true, 'is-danger': errors.has('simsel') }"
              :disabled="subsel == 0" />
            <span style="color:red" v-show="errors.has('simsel')">** required</span>
          </b-col>

          <b-col class="col-12 mt-3" v-else>
            <label>SIM Card</label>
            <b-btn v-if="nosimm" variant="warning" block size="sm" disabled>User supplied sim</b-btn>
            <b-btn v-if="simms.length == 0" variant="danger" block size="sm" disabled>No available stock sims</b-btn>
          </b-col>

        </b-row>

        <b-row class="mt-3">

          <b-col class="col-12 col-sm-6 mt-2">
            <label>Activation Date</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-1">

            <VueCtkDateTimePicker v-model="datesel1" format='DD-MM-YYYY HH:mm' :dark="true"
              :no-value-to-custom-elem="true" :only-date="false">
              <b-form-input type="search" v-model="datesel1" autocomplete="off" placeholder="select" class="darkinput"
                name="datesel1" v-validate="{ required: true }"
                :class="{ 'b-form-select': true, 'is-danger': errors.has('datesel1') }"
                :disabled="subsel == 0 || activatenow == 1" />
            </VueCtkDateTimePicker>

          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>Expiration Date</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2" v-show="subsel > 0">

            <VueCtkDateTimePicker v-model="expire" format='DD-MM-YYYY HH:mm' :dark="true"
              :no-value-to-custom-elem="true" :only-date="false">
              <b-form-input type="search" v-model="expire" autocomplete="off" placeholder="-" class="darkinput"
                name="expire" v-validate="{ required: true }"
                :class="{ 'b-form-select': true, 'is-danger': errors.has('expire') }" disabled />
            </VueCtkDateTimePicker>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3 pl-4" v-show="subsel == 0"> - </b-col>

          <b-col class="col-12 mt-3">
            <b-form-checkbox v-model="activatenow" inline value=1 unchecked-value=0 size="lg" :disabled="subsel == 0" />
            <label>Start from current date/time</label>
          </b-col>

        </b-row>

        <b-row class="mt-2">

          <b-col class="col-12 col-sm-6 mt-3">
            <label>NTRIP Username</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2">
            <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.username" class="darkinput" />
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>NTRIP Password</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2">
            <b-form-input type="search" placeholder="password" autocomplete="off" v-model="sub.password"
              class="darkinput" />
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>Connection IP/URL</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2">
            <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.connection" class="darkinput" />
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>Connection Port</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2">
            <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.port" class="darkinput" />
          </b-col>

          <b-col class="col-12 col-sm-6 mt-3">
            <label>Mount Point</label>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-2">
            <b-form-input type="search" placeholder="" autocomplete="off" v-model="sub.mount" class="darkinput" />
          </b-col>

        </b-row>

        <b-row>

          <b-col class="col-6 mt-3">
            <b-button variant="outline-warning" block size="sm" @click.stop="toggleAdvanced()">Optional advanced
              settings</b-button>
          </b-col>

          <b-col class="col-12 mt-3" v-if="showadvanced">
            <b-form-checkbox v-model="sub.needs_gga" size="lg" value=1 inline unchecked-value=0 />
            <label>Needs GGA</label>
          </b-col>


          <b-col class="col-12 mt-3" v-if="showadvanced">
            <b-form-checkbox v-model="sub.disconnect_onchangebase" size="lg" inline value=1 unchecked-value=0 />
            <label>Disconnect base change</label>
          </b-col>

        </b-row>

        <b-row class="pb-5">
          <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="validateSub()" block>{{activateSubBtnText}}</b-button>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <b-modal ref="assign-sub" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
      <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
          {{ message.message }}
        </b-col>
        <b-col class="col-12 col-sm-5">
          {{ message.text }}
        </b-col>
        <b-col class="col-12 col-sm-2 text-right">
          <b-icon-check-square v-if="message.error == false" />
          <b-icon-x-square v-else />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 mt-5">
          <b-button variant="outline-success" @click.stop="finish()" block>Finish</b-button>
        </b-col>
      </b-row>

    </b-modal>

    <SendEmail :sendmail="sendmail" :emailid="emailid" :emailto="emailto" :subid="newsubid" :companyid="companyid"
      :showdiag=false v-on:emailsent="emailSendStatus" />

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
    <LoadCompanyData :reloaddata="reloaddata" />

  </div>
</template>

<script>
import SendEmail from './SendEmail'
import ErrorLogging from './ErrorLogging'
import LoadCompanyData from './LoadCompanyData'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'SubscriptionAssign',
  components: {
    SendEmail,
    ErrorLogging,
    LoadCompanyData
  },
  data() {
    return {
      token: '',
      subtypes: [{ value: 0, text: 'Select' }],
      simms: [{ value: 0, text: 'Select' }],
      subsel: 0,
      simsel: 0,
      renew:false,
      sub: {},
      rover: {},
      expire: '',
      nosimm: false,
      activatenow: 1,
      daysremaining:0,
      datesel1: '',
      showadvanced: false,
      sendwelcomeemail: 1,
      emailtypetext: '',
      sendmail: false,
      sent: 99,
      emailto: '',
      emailid: 12, // Welcome - 1 , NTRIP Details - 12
      newsubid: 0,
      showdiag: false,
      sending: false,
      errorresponse: {},
      reloaddata: 0,
      stagemessages: [],
      modaltitle: '',
      activateSubBtnText: 'Activate Subscription',
   
      subrenew:false,
      display:false
    };

  },

  
  watch: {
    activatenow: function () {
      if (this.activatenow == 1) {
        this.datesel1 = moment().format('DD-MM-Y HH:mm')
        this.setDate()
      } else {
        //old this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm')
        this.datesel1 = moment(this.rover.subscriptiondetail[0].enddate2).format('DD-MM-Y HH:mm')
        this.setDate()
      }
      this.sub.activatenow = this.activatenow
    },

    datesel1: function () {
      if (this.subsel != 0) {
        this.setDate()
      }
    }
  },

  created() {
    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.emailto = this.$store.getters.company.email
    this.companyid = this.$store.getters.companyid

    this.datesel1 = moment().format('DD-MM-Y HH:mm')

    // Check for renewl and if so get store values for sub amd rover
    if(this.$route.params.id == 0){
     
    this.subrenew = true  
    this.rover = this.$store.getters.selectedrover
    this.sub = this.$store.getters.selectedsub
    this.subsel = this.sub.id
    if(this.subsel == undefined){this.subsel = this.sub.value}

    // Get bought sub values
    //console.log(this.rover)
    //console.log(this.sub)

   
    } else {
      // Get stored rover value and decouple object
      this.subrenew = false
      this.rover = JSON.parse(JSON.stringify(this.$store.getters.rovers)).filter(roversel => roversel.value == this.$route.params.id)[0]
      this.simms.push(...this.$store.getters.stocksims)
      this.daysremaining = this.rover.subscriptiondetail[0].expirydays
      if(this.daysremaining > 0){
        this.datesel1 = moment(this.rover.subscriptiondetail[0].enddate2).format('DD-MM-Y HH:mm')
      } else this.activatenow = 1
    }
    let subs = this.$store.getters.stocksubs.filter(sub => sub.status != 10)
    this.subtypes.push(...subs)
  
    this.sub.user = this.userid //  Set user made change
    this.sub.username = this.rover.username
    this.sub.password = this.rover.password
    this.sub.port = this.rover.port
    this.sub.connection = this.rover.connection
    this.sub.mount = 'RTK_RTCM3'

    if(this.$route.params.id == 0){
      this.setSub(this.subsel)
    }

    //this.display = true
    //this.setValues()
  },

  methods: {


    setValues(){

    

    },

    setSub(event) {
      let data = this.subtypes.filter(sub => sub.value == event)[0]
      
      if(data !== undefined){
        this.sub.type = data.type
        this.sub.subid = data.value
        this.sub.title = data.subscription[0].title
        this.sub.days = data.subscription[0].days
        this.sub.months = data.subscription[0].months
        this.sub.years = data.subscription[0].years
        this.nosimm = !data.subscription[0].simm
        //this.$validator.validate('subsel')
        this.expire = ''

        if (data.value == 1) { // is active sub so use current start/end dates
          this.datesel1 = this.activestart
          this.expire = this.activeend
        } else {
          if(this.daysremaining > 0){
              this.datesel1 = moment(this.rover.subscriptiondetail[0].enddate2).format('DD-MM-Y HH:mm')
          } else this.datesel1 = moment().format('DD-MM-Y HH:mm')
          this.setDate()
        }

        this.display = true

      } else alert('sub not found')
    },

    setSimm() {
      let sim = this.simms.filter(simm => simm.value == this.simsel)
      this.sub.simmtext = sim[0].number
      this.sub.simm = this.simsel
      this.$validator.validate('simsel')
    },

    setDate() {
      let datetime = moment(this.datesel1, "DD-MM-YYYY HH:mm")

      if (this.sub.days !== 0) {
        this.sub.end = moment(datetime).add(this.sub.days, 'days')
      }
      if (this.sub.months !== 0) {
        this.sub.end = moment(datetime).add(this.sub.months, 'months')
      }
      if (this.sub.years !== 0) {
        this.sub.end = moment(datetime).add(this.sub.years, 'years')
      }
      this.expire = moment(this.sub.end).format('DD-MM-YYYY HH:mm')
      this.sub.start = moment(datetime).format('DD-MM-YYYY HH:mm')
      this.sub.end = this.expire

     // this.$validator.validate('datesel1')
     // this.$validator.validate('expire')
    },

    validateSub() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.activateSub()
        }
      })
    },

    activateSub() {  //  Activate subscription

      this.stagemessages = []
      this.modaltitle = 'Activating Subscription'
      if(this.$store.getters.admindialog == true){this.$refs['assign-sub'].show()}


      axios({
        method: 'post',
        url: '/api/portalupdatesubscription',
        headers: { "Authorization": "Bearer " + this.token },
        data: {
          userid: this.userid,
          token: this.token,
          subid: this.sub.subid,
          simmid: this.simsel,
          type: this.sub.type,
          substart: this.sub.start,
          subend: this.sub.end,
          connection: this.sub.connection,
          port: this.sub.port,
          needsGGA: this.sub.needs_gga,
          changebase: this.sub.disconnect_onchangebase,
          rover: this.rover.value,
          company: this.companyid,
          now: this.activatenow
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
            this.saving = false
          } 
          else
          if( response.data.error == true){
            this.stagemessages.push(...response.data.actions)
            this.$refs['assign-sub'].show()
          }
          else {
            this.newsubid = response.data.subid
            this.stagemessages.push(...response.data.actions)
            this.reloaddata = 3 // Trigger reload of rovers/subs/simms
            this.sendEmail()
            this.activateSubBtnText = 'Subscription Activated'
            // Clear store sub and rover for renewal
            this.$store.commit('setselectedrover','')
            this.$store.commit('setselectedsub','')
            setTimeout( this.finish, 3000);
          }

        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    finishRenewal() {
      this.$emit('update')
    },

    toggleAdvanced() {
      this.showadvanced = !this.showadvanced
    },

    sendEmail() {
      this.sendmail = !this.sendmail // trigger to SendEmail
    },

    emailSendStatus(data) {
      this.stagemessages.push(...data.messages)
    },

    finish() {
      this.$router.push({ name: 'roverslist', params: {} }).catch(()=>{})
      //history.back()
    },

    closemodal() {
      //this.$emit('closemodal')
    },

  }
};
</script>