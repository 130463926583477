<template>
<div>
<NavBarBlank v-if="!loggedin && route != 'gdprcompliance'" />
<NavBarGDPR v-if="route == 'gdprcompliance'" />
<router-view  />

</div>
</template> 

<script>
    import NavBarBlank from './NavBarBlank'
    import NavBarGDPR from './NavBarGDPR' 
    import { mapState } from 'vuex'

	export default {
        name: 'HomeLanding',
        components:{
            NavBarBlank,
            NavBarGDPR,
            },
    props:['pwtoken','pwemail'],
    data(){
        return {
            route:''
        };
    },       


    mounted(){
       
        if(this.pwtoken && this.pwemail){ // check for pw reset link
            this.$router.push({ name: 'reset-password-form', params: {token:this.pwtoken,email:this.pwemail} }).catch(()=>{})
         }
         if(this.$route.name == 'gdprcompliance'){
            this.$router.push({ name: 'gdprcompliance', params: {id:this.$route.params.id} }).catch(()=>{})
         }
           
         
    },

    computed: mapState(['loggedin']),
    
               watch: {
                    loggedin: function () {
                         if(!this.loggedin){this.logout()}
                         },         
            },
    
    methods : {

        logout(){ 
                this.$store.commit('settoken','')
                this.$store.commit('setuser','')
                this.$store.commit('setcompany',{})
                this.$store.commit('setloggedin',false)
                this.$router.push({ name: 'login', params: {} }).catch(()=>{}) 
            },

    }
}
</script>