<template>
    <div class="borderlight" style="font-size:0.8em;color: rgba(255, 255, 255, 0.7);">
    <b-container>
        
        <b-row class="mt-2">
                <b-col class="col-6">REFERENCE STATION</b-col>
                <b-col class="col-6">{{ sessiondata.basestation }}</b-col>
            </b-row>
    
            <b-row class="mt-1">
                <b-col class="col-6">QUALITY</b-col>
                <b-col class="col-6">{{ sessiondata.quality }}%</b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col class="col-6">Num Satellites</b-col>
                <b-col class="col-6">{{ sessiondata.num_sats }}</b-col>
            </b-row>
    
             <b-row class="mt-1">
                <b-col class="col-6">#GPGGAs</b-col>
                <b-col class="col-6">{{ sessiondata.ggas }}</b-col>
            </b-row>
    
            <b-row class="mt-1 pb-2">
                <b-col class="col-6">TIME TO FIX</b-col>
                <b-col class="col-6">{{ sessiondata.time_to_fix }}s</b-col>
            </b-row>
    
</b-container>    
</div>
</template>
    
    <script>
        export default {
            props:['sessiondata'],
            data(){
            return {
                      
                    };
                    
                    },
    
            created(){
                  
            },
    
       
        };
    </script> 