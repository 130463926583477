// customRule.js
import { Validator } from 'vee-validate';

Validator.extend('customRule', {
  getMessage(field,args) {
    if(args == 'true'){
        return 'Email already exists'
    }
  },
  validate(value, args) {
    if(args == 'false'){
        return true;
        } else return false;
    },
});