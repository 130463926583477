<template>
<div class="borderlight borderpadd" style="color: rgba(255, 255, 255, 0.7);">
<b-container>

            <b-row class="mt-1" >
                <b-col class="col-6 col-md-4">GSM Username</b-col>
                <b-col class="col-6 col-md-8">*leave blank</b-col>
                <b-col class="col-6 col-md-4">GSM Password</b-col>
                <b-col class="col-6 col-md-8">*leave blank</b-col>
                <b-col class="col-6 col-md-4">GSM APN</b-col>
                <b-col class="col-6 col-md-8">{{  gsmapn }}</b-col>
                <b-col class="col-6 col-md-4">Roaming</b-col>
                <b-col class="col-6 col-md-8">Enabled</b-col>
                <b-col class="col-6 col-md-4">NTRIP Address</b-col>
                <b-col class="col-6 col-md-8">{{ rover.connection }}</b-col>
                <b-col class="col-6 col-md-4">NTRIP Port</b-col>
                <b-col class="col-6 col-md-8">{{ rover.port }}</b-col>
                <b-col class="col-6 col-md-4">Mount Point</b-col>
                <b-col class="col-6 col-md-8">RTK_RTCM3</b-col>
                <b-col class="col-6 col-md-4">NTRIP User</b-col>
                <b-col class="col-6 col-md-8">{{ rover.username }}</b-col>
                <b-col class="col-6 col-md-4">NTRIP Password</b-col>
                <b-col class="col-6 col-md-8">{{ rover.password }}</b-col>
            </b-row>

            <b-row>
                <b-col class="col-12 col-sm-6 mt-3">
                    <b-button block variant="outline-success" @click="emailDetails()">Email NTRIP details</b-button>
                </b-col>

                <b-col class="col-12 col-sm-6 mt-3">
                    <b-button block variant="outline-success" @click="copyText()">Copy to clipboard</b-button>
                </b-col>
              
            </b-row> 
            

<b-modal ref="email-details" title="Email NTRIP Details" @hide="closeModal" hide-footer centered content-class="darkmodal">
    <b-row>
        <b-col class="col-12">
                        <label>Email Address</label>
                        <b-form-input 
                        type="search"
                        placeholder=""
                        autocomplete="off" 
                        v-model="emailto" 
                        class="darkinput"  
                        />
        </b-col>

        <b-col class="col-12 mt-3">
            <b-button block variant="outline-success" @click="sendEmail()">Send</b-button>
        </b-col>

                
    </b-row>
</b-modal>

<b-modal ref="copy-clipboard" size="md"  hide-header hide-footer centered content-class="darkmodal">
    <b-row class="mt-1">
        <b-col class="col-12 text-center"><b-icon icon='check2-circle' scale=2 variant="success" style="margin-right:10px" /> NTRIP details copied to clipboard</b-col>
    </b-row>
</b-modal>        

<SendEmail 
        :sendmail="sendmail"
        :emailid="emailid" 
        :emailto="emailto" 
        :subid="rover.subscription"
        :showdiag="showdiag"
        v-on:emailfinish="emailSendStatus"
        />

<ErrorLogging 
    :errorresponse="errorresponse" 
    :component="this.$options.name"
    />

</b-container>    
</div> 
</template>


<script>
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    export default {
    props:['rover','subid','email'],    
    name:'CasterRoverNTRIPDetails',
    components:{
            SendEmail,
            ErrorLogging
        },
    data(){
		return {
            sendmail:false,
            emailto:'',
            emailid:12, // NTRIP details email template
            showdiag:true,
            role:0,
            errorresponse:{},
            gsmapn:'n/a',
            text: 'This will get copied!',
            };
        },
		created(){
            this.role = this.$store.getters.roleid
            this.emailto = this.email
            if(this.rover.simm[0] !== undefined){
                this.gsmapn = this.rover.simm[0].apn
            }
       },

        methods: {

            sendEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(){
                this.$refs['email-details'].hide()
            },

            copyText() {
                this.text = "GSM Username *leave blank\nGSM Password *leave blank\nGSM APN n/a\nRoaming Enabled\nNTRIP Address "+this.rover.connection+"\nNTRIP Port "+this.rover.port+"\nMount Point RTK_RTCM3\nNTRIP User "+this.rover.username+"\nNTRIP Password "+this.rover.password+"\n"
                let textarea = document.createElement('textarea');
                textarea.value = this.text
                document.body.appendChild(textarea)
                textarea.select()
                document.execCommand('copy')
                document.body.removeChild(textarea)
                this.$refs['copy-clipboard'].show()
                setTimeout(() => {this.$refs['copy-clipboard'].hide()}, 2000);
            },

            emailDetails(){
                this.$refs['email-details'].show()
            },

            closeModal(){
               
            }

           

            }
	}
</script> 