<template>
  <div>

    <b-row>
      <b-col class="col-12 col-sm-6 offset-sm-3">

        <b-row>
          <b-col class="col-12">
            <h4>Checkout</h4>
          </b-col>

          <b-col class="col-5 mt-3">
            <h6>Subscription</h6>
          </b-col>
          <b-col class="col-7 mt-3">
            <h6>{{ sub.title }}</h6>
          </b-col>

          <b-col class="col-5 mt-3">
            <h6>Start</h6>
          </b-col>
          <b-col class="col-7 mt-3">
            <h6>{{ formatDate(sub.start) }}</h6>
          </b-col>

          <b-col class="col-5 mt-3">
            <h6>End</h6>
          </b-col>
          <b-col class="col-7 mt-3">
            <h6>{{ formatDate(sub.end) }}</h6>
          </b-col>

        </b-row>

       
    <b-row>
      <b-col class="col-12  mt-3">
              <h4>Payment</h4>
      </b-col>

      <!-- <b-col class="col-12 text-center mt-3" >
              <h6 v-if="test">STRIPE TEST MODE</h6>
              <h6 v-else>STRIPE LIVE MODE</h6>
      </b-col> -->
    </b-row>
     
    <b-row>

      <b-col class="col-12 mt-3">
              <PaymentStripeEmbed :formerrors="false" v-on:paymentcomplete="paymentComplete" />
      </b-col>

      <b-col class="col-12  p-0">  
                <PaymentBasket />
      </b-col>
    
      <b-col class="col-12  mt-5">
         <img :src="stripelogo" style="width:90%;margin-left:5%">  
       </b-col>
      
    </b-row>

        <!-- <b-row class="pb-5 mt-5">
          <b-col class="col-12 col-sm-5 mt-3">
            <b-button variant="outline-success" @click.stop="renewSub()" block><b-icon-check-circle
                class="mr-2" />Renew</b-button>
          </b-col>
          <b-col class="col-12 col-sm-5 offset-sm-2 mt-3">
            <b-button variant="outline-warning" @click.stop="cancel()" block><b-icon-x-circle
                class="mr-2" />Cancel</b-button>
          </b-col>
        </b-row> -->

      </b-col>
    </b-row>

    <b-modal ref="assign-sub" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
      <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
          {{ message.message }}
        </b-col>
        <b-col class="col-12 col-sm-5">
          {{ message.text }}
        </b-col>
        <b-col class="col-12 col-sm-2 text-right">
          <b-icon-check-square v-if="message.error == false" />
          <b-icon-x-square v-else />
        </b-col>
      </b-row>

      <b-row>
        <b-col class="col-12 mt-5">
          <b-button variant="outline-success" @click.stop="finish()" block>Finish</b-button>
        </b-col>
      </b-row>

    </b-modal>

    <SendEmail :sendmail="sendmail" :emailid="emailid" :emailto="emailto" :subid="newsubid" :companyid="companyid"
      :showdiag=false v-on:emailsent="emailSendStatus" />

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
   
  </div>
</template>

<script>
import PaymentStripeEmbed from './PaymentStripeEmbed'
import PaymentBasket from './PaymentBasket'
import SendEmail from './SendEmail'
import ErrorLogging from './ErrorLogging'
import stripelogo from '@/assets/images/stripe-badge-transparent.png'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'SubscriptionAssign',
  components: {
    SendEmail,
    ErrorLogging,
    PaymentStripeEmbed,
    PaymentBasket
  },
  data() {
    return {
      token: '',
      userid: 0,
      sub: {},
      sendwelcomeemail: 1,
      emailtypetext: '',
      sendmail: false,
      sent: 99,
      emailto: '',
      emailid: 12, // Welcome - 1 , NTRIP Details - 12
      newsubid: 0,
      showdiag: false,
      sending: false,
      errorresponse: {},
      stagemessages: [],
      modaltitle: '',
      stripelogo,
      total:0,
      test:false,
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.emailto = this.$store.getters.company.email
    this.companyid = this.$store.getters.companyid
    this.sub = this.$store.getters.subsel
    if(this.$store.getters.user.role == 1){this.test = true} else this.test = false
  },


  methods: {

    paymentComplete(data){
          this.$store.commit('setpaymentcomplete',true)
          this.bitems = this.$store.getters.basket
          data.basket = this.bitems
          this.$store.commit('setpayment',data)
          this.$router.push({ name:'paymentcomplete', params: {} }).catch(()=>{})
    },

    renewSub() {  //  Activate subscription

      // this.stagemessages = []
      // this.modaltitle = 'Activating Subscription'
      // this.$refs['assign-sub'].show()


      axios({
        method: 'post',
        url: '/api/portalrenewsubscription',
        headers: { "Authorization": "Bearer " + this.token },
        data: {
          userid: this.userid,
          token: this.token,
          sub: this.sub
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
            this.saving = false
          } else {
            // this.newsubid = response.data.subid
            //this.stagemessages.push(...response.data.actions)
            this.sendEmail()
          }

        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    finishRenewal() {
      this.$emit('update')
    },

    formatDate(date) {
      return moment(date).format('MMM Do YYYY, h:mm a')
    },

    sendEmail() {
      this.sendmail = !this.sendmail // trigger to SendEmail
    },

    emailSendStatus(data) {
      this.stagemessages.push(...data.messages)
    },

    finish() {
      history.back()
    },

    cancel() {
      history.back()
    },

  }
};
</script>