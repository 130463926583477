<template>
  <div>
    <b-row>
      <b-col class="col-12 col-sm-6 offset-sm-3">
        <b-row>
          <b-col class="col-7">
            <h4>Session History</h4>
          </b-col>
          <b-col class="col-5 text-right">
            <b-button size="sm" variant="outline-success" @click="showlive()">
              <b-icon-clock-history class="mr-2" />VIEW LIVE
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12">
            <h5>{{ rover.text }}</h5>
            <h6>Session started : {{ formatTime(sessiondata.datetime) }}</h6>
            <h6>Session duration : {{ formatDuration(sessiondata.connection_time) }}</h6>

          </b-col>
        </b-row>


        <b-row class="pb-5">

          <b-col class="col-12 mt-1 p-2">
            <RoverSessionHistoryTable :sessionid="sessionid" :id="roverid" v-on:session="setSession" />
          </b-col>

          <b-col class="col-12 mt-1 p-2">
            <MapLeafletRoverSession :mapdata="currentsession" :border="true" :reset="resetbounds"/>
          </b-col>

          <b-col class="col-12 mt-1 p-2">
            <RoverSessionHistoryPanel :session="sessiondata" />
          </b-col>

          <b-col class="col-12 mt-1 p-2">
            <RoverLastEventsCard :logs="currentsession.logs" />
          </b-col>

        </b-row>

      </b-col>
    </b-row>



    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import RoverLastEventsCard from './RoverLastEventsCard'
import MapLeafletRoverSession from './MapLeafletRoverSession'
import RoverSessionHistoryPanel from './RoverSessionHistoryPanel'
import RoverSessionHistoryTable from './RoverSessionHistoryTable'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'RoverSessionHistory',
  components: {
    RoverLastEventsCard,
    MapLeafletRoverSession,
    RoverSessionHistoryPanel,
    RoverSessionHistoryTable,
    ErrorLogging
  },
  data() {
    return {
      roverid: 0,
      rover: {},
      currentsession: {},
      sessiondata: {},
      rtkstats: {},
      mapshow: true,
      session: {},
      sessionid: 0,
      errorresponse: {},
      live: false,
      resetbounds:false,
      token: ''
    };

  },

  created() {
    this.token = this.$store.getters.user.token
    this.roverid = this.$route.params.id // Rover ID
    this.rover = this.$store.getters.rovers.filter(roversel => roversel.value == this.roverid)[0]
    this.currentsession = this.$store.getters.roversession
    this.sessiondata = this.$store.getters.roversession.session[0]
    this.loadSession()

  },

  watch: {
    sessionid: function () {
      this.getRoverSession()
    },
  },

  methods: {

    loadSession() {
      this.rtkstats = this.currentsession.rtkstats
      this.rtkstats.quality = this.sessiondata.quality
      this.sessiondata.rtkstats = this.rtkstats
    },

    getRoverSession() {

      axios.get('/api/portalroversession', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.token,
          rover: this.roverid,
          session: this.sessionid
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.currentsession = response.data
            this.sessiondata = this.currentsession.session[0]
            this.resetbounds = !this.resetbounds
            this.loadSession()

          }
        });
    },

    setSession(event) {

      if (event !== undefined) {
        this.session = event
        this.sessionid = this.session.session_id
        if (this.rover.lastsession == this.sessionid) { this.live = true } else this.live = false
      }
    },

    formatTime(datetime) {  // BST Fiddle
      let time = moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
      return time
    },

    formatDuration(start) {

      let days = parseInt(start / 86400)
      let hours = Math.trunc(parseInt(start - (days * 86400)) / 3600)
      let minutes = Math.trunc(parseInt((start - (days * 86400) - (hours * 3600)) / 60))
      let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60)))
      let duration = days + 'd ' + hours + 'h ' + minutes + 'm ' + secs + 's'
      return duration
    },

    showlive() {
      this.$router.push({ name: 'roverdisplay', params: { id: this.rover.value } }).catch(() => { })
    },
  }
};
</script>