<template>
  <div>
    <b-container>
      <b-row>

        <b-col class="col-12 col-sm-6 offset-sm-3">

          <b-row>
            <b-col class="col-12 mt-3">
              <h4>Edit Company</h4>
            </b-col>
          </b-row>

          <b-row>

            <b-col class="col-12 col-sm-4 mt-3">
              <label>Company Name</label>
            </b-col>
            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.title" name="company.title"
                v-validate="{ required: true }" :class="{ 'darkinput': true, 'is-danger': errors.has('company.title') }"
                @input="validateField('company.title')" @change="validateField('company.title')"
                :disabled="user.role == 30" />
              <span style="color:red;">{{ errors.first('company.title') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-4 mt-3">
              <label>Address</label>
            </b-col>
            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.address1"
                name="company.address1" v-validate="{ required: true }"
                :class="{ 'darkinput': true, 'is-danger': errors.has('company.address1') }"
                @input="validateField('company.address1')" @change="validateField('company.address1')"
                :disabled="user.role == 30" />
              <span style="color:red;">{{ errors.first('company.address1') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-8 offset-sm-4 mt-3">
              <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="company.address2"
                name="company.address2" class="darkinput" :disabled="user.role == 30" />
            </b-col>

            <b-col class="col-12 col-sm-8 offset-sm-4 mt-3">
              <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="company.address3"
                name="company.address3" class="darkinput" :disabled="user.role == 30" />
            </b-col>

            <b-col class="col-12 col-sm-4 mt-3">
              <label>Town / City</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.towncity"
                name="company.towncity" v-validate="{ required: true }"
                :class="{ 'darkinput': true, 'is-danger': errors.has('company.towncity') }"
                @input="validateField('company.towncity')" @change="validateField('company.towncity')"
                :disabled="user.role == 30" />
              <span style="color:red;">{{ errors.first('company.towncity') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-4 mt-3" v-if="company.country == 1">
              <label>County</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.county"
                name="company.county" class="darkinput" :disabled="user.role == 30" />
            </b-col>


            <b-col class="col-12 col-sm-4 mt-3">
              <label>Postcode</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.postcode"
                name="company.postcode" class="darkinput" :disabled="user.role == 30" />
            </b-col>


            <b-col class="col-12 mt-3">
              <b-form-checkbox inline v-model="company.del_addresssame" size="lg" value=1 unchecked-value=0
                :disabled="user.role == 30" />
              <label style="margin-right:15px">Delivery address same as above </label>
            </b-col>

          </b-row>

          <b-row v-if="company.del_addresssame == 0">

            <b-col class="col-12 col-sm-4 mt-3">
              <label>Delivery Address</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.del_address1"
                name="company.del_address1" v-validate="{ required: true }"
                :class="{ 'darkinput': true, 'is-danger': errors.has('company.del_address1') }"
                @input="validateField('company.del_address1')" @change="validateField('company.del_address1')"
                :disabled="user.role == 30" />
              <span style="color:red;">{{ errors.first('company.address1') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-8 offset-sm-4 mt-3">
              <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="company.del_address2"
                name="company.del_address2" class="darkinput" :disabled="user.role == 30" />
            </b-col>

            <b-col class="col-12 col-sm-8 offset-sm-4 mt-3">
              <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="company.del_address3"
                name="company.del_address3" class="darkinput" :disabled="user.role == 30" />
            </b-col>

            <b-col class="col-12 col-sm-4 mt-3">
              <label>Town / City</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.del_towncity"
                name="company.del_towncity" v-validate="{ required: true }"
                :class="{ 'darkinput': true, 'is-danger': errors.has('company.del_towncity') }"
                @input="validateField('company.del_towncity')" @change="validateField('company.del_towncity')"
                :disabled="user.role == 30" />
              <span style="color:red;">{{ errors.first('company.del_towncity') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-4 mt-3" v-if="company.country == 1">
              <label>County</label>
            </b-col>

            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.del_county"
                name="company.del_county" class="darkinput" :disabled="user.role == 30" />
            </b-col>


            <b-col class="col-12 col-sm-4 mt-3">
              <label>Postcode</label>
            </b-col>
            <b-col class="col-12 col-sm-8 mt-3">
              <b-form-input type="search" placeholder="" autocomplete="off" v-model="company.del_postcode"
                name="company.del_postcode" class="darkinput" :disabled="user.role == 30" />
            </b-col>

          </b-row>

          <b-row>

            <b-col class="col-12 col-sm-6 mt-3">
              <label>Company Admin User</label>
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
              <b-form-select v-model="company.adminuser" :options="companyadmins" class="darkinput"
                name="company.adminuser" v-validate="{ min_value: 1 }"
                :class="{ 'b-form-select': true, 'is-danger': errors.has('company.adminuser') }"
                :disabled="user.role == 30" />
              <span v-if="errors.has('company.adminuser')" style="color:red;">Select an admin user</span>
            </b-col>

            <!-- <b-col class="col-12 mt-3">
                <label>Account #</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="company.account" 
                    class="darkinput"
                    :disabled="user.role == 30"  
                    />
        </b-col> -->


          </b-row>


          <b-row class="mt-5 mb-5" v-if="!updated">
            <b-col class="col-12 col-md-5 mt-3">
              <b-button variant="outline-success" :disabled="user.role == 30" size="md" block
                @click="updateCompany()"><b-icon-check-circle class="mr-2" />Save</b-button>
            </b-col>

            <b-col class="col-12 col-md-5 offset-md-2 mt-3">
              <b-button variant="outline-warning" :disabled="user.role == 30" size="md" block
                @click="finishCompany()"><b-icon-x-circle class="mr-2" />Cancel</b-button>
            </b-col>
          </b-row>

          <b-row class="mt-5 mb-5" v-else>
            <b-col class="col-12 mt-3">
              <b-button variant="outline-success" size="md" block @click="finishCompany()"><b-icon-check-circle
                  class="mr-2" />Company Updated</b-button>
            </b-col>
          </b-row>


        </b-col>
      </b-row>
    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>

<script>
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
import { Validator } from 'vee-validate'
import './customRule';
export default {
  name: 'CompanyEdit',
  components: {
    ErrorLogging,
  },
  data() {
    return {
      token: '',
      userid: 0,
      company: {},
      user: {},
      companyadmins: [],
      modaltitle: '',
      updated: false,
      edit: false,
      errorresponse: {},
    };

  },

  created() {

    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.company = JSON.parse(JSON.stringify(this.$store.getters.company))
    this.users = this.company.users

    this.companyadmins.push({ value: 0, text: 'Select Admin' })
    this.company.users.forEach(element => {
      if (element.role == 20) {
        this.companyadmins.push({ value: element.id, text: element.fname + ' ' + element.lname })
      }
    });

  },

  mounted() {
    Validator.localize('en', {
      messages: {
        required: '** required.',
      },
    })
  },

  methods: {

    editDetails() {
      this.edit = true
    },

    validateField(field) {
      this.$validator.validate(field)
    },

    finishCompany() {
      history.back()
    },

    updateCompany() {

      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/portalstorecompany',
        data: {
          userid: this.userid,
          token: this.token,
          companydata: this.company
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.$store.commit('setcompany', response.data.company[0])
            this.updated = true
          }

        }).catch((err) => {
          this.errorresponse = err;
        });
    },

  }

};
</script>