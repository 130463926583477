import { render, staticRenderFns } from "./PaymentBasket.vue?vue&type=template&id=2316ba69&scoped=true"
import script from "./PaymentBasket.vue?vue&type=script&lang=js"
export * from "./PaymentBasket.vue?vue&type=script&lang=js"
import style0 from "./PaymentBasket.vue?vue&type=style&index=0&id=2316ba69&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2316ba69",
  null
  
)

export default component.exports