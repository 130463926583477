<template>
    <div class="borderlight" style="font-size:0.8em;color: rgba(255, 255, 255, 0.7);">
    <b-container>
        <b-row>
        <b-col class="col-12 mt-1 pb-1">
                <b-row class="mt-1">
                <b-col class="col-3">FIXED</b-col>
                <b-col class="col-3"> {{ stats.fix }}%</b-col>
                <b-col class="col-3">FLOAT</b-col>
                <b-col class="col-3"> {{ stats.float }}%</b-col>
                <b-col class="col-3">DGPS</b-col>
                <b-col class="col-3"> {{ stats.dgps }}%</b-col>
                <b-col class="col-3">GPS</b-col>
                <b-col class="col-3"> {{ stats.gps }}%</b-col>
                <b-col class="col-3">EST</b-col>
                <b-col class="col-3"> {{ stats.est }}%</b-col>
                <b-col class="col-3">QUALITY</b-col>
                <b-col class="col-3"> {{ stats.quality }}%</b-col>
            </b-row>
            </b-col>
        </b-row>
</b-container>    
</div>
</template>
    
    <script>
        export default {
            props:['stats'],
            data(){
            return {
                      
                    };
                    
                    },
    
            created(){
                  
            },
    
       
        };
    </script> 