<template>
    <div class="borderlight">
    
            <b-row class="mt-1" style="font-size:0.8em;">
                <b-col class="col-12" style="max-height:300px;overflow-y: hidden;">
                   
                    <b-table 
                    striped 
                    responsive 
                    hover 
                    :items="messages" 
                    :fields="fields"
                    sort-icon-left
                    sticky-header="280px"
                    tbody="rowClassSm"
                    dark 
                    >
                     <template #cell(colour)="data">
                      <div class="rtmarker" :style="{ backgroundColor:data.item.Colour,width:'15px',height:'15px' }" />
                    </template>
                    
                    <template #cell(message)="data">
                        {{ gettime(data.item.Timestamp) }} [{{ data.item.Session_id }}] <br/>{{ data.item.Message }}
                    </template>
                    </b-table>
                  
                </b-col>    
            </b-row>
    
    
    </div>
    </template>
    
<style scoped>
    .red{color:#ffffff;}
    .green{color:#cccccc;}
</style>
    
    <script>
        import moment from 'moment'
        export default {
            props:['logs'],
            data(){
            return {
                    messages:[],
                    fields: [
                        { key: 'Colour', label: '', sortable: false}, 
                        { key: 'Message', label: 'LATEST EVENTS', sortable: false}, 
                    ],
                    };
                    
                    },
    
            created(){
                this.messages = this.logs
            },
    
            watch: {
                        logs: function () {
                           this.messages = this.logs
                        },   
            },
    
            methods: {
    
                setRowClass(item){
                   
                    this.time = Math.round(+new Date()/1000)
                    if((this.time - item.timestamp) > 30){return 'green'} else return 'red'
                },
    
    
                gettime(time){
                    let datetime = moment.utc(time).local().format('DD-MM-YYYY HH:mm:ss');
                    return datetime
                }
    
            }	   
        
        };
    
    </script> 