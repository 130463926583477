<template>
  <div class="borderlight borderpadd" style="color: rgba(255, 255, 255, 0.7);">
    <b-container>

      <b-row>
            <b-col class="col-12">
                <h6>SESSION HISTORY</h6>
              </b-col>
        </b-row>

      <b-row class="mt-0">
        <b-col class="col-12 mt-2" style="max-height:300px;overflow-y: hidden;">

          <div v-if="sessions.length > 0">
            <b-table 
           
              ref="sessionstable" 
              responsive 
              hover 
              :items="sessions" 
              :fields="fields" 
              select-mode="single"
              selectable 
              tbody-tr-class="rowClass" 
              thead-tr-class="rowHClass" 
              sort-icon-left
              sticky-header="280px"
              @row-clicked="showSession($event)" dark />

          </div>

          <div v-else>

            <b-row class="mt-3" style="min-height: 405px;">
              <!--needs a min hight for the picker as its absolutly positioned goes off screen otherwise-->
              <b-col class="col-12 text-center pt-3 pb-3">
                <h5>No session history for Rover</h5>
              </b-col>
            </b-row>

          </div>


        </b-col>
      </b-row>
    </b-container>
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>

<style>
.datePrepend>.input-group-text {
  background-color: var(--naDarkBlue);
  border-color: var(--cbGreenFade);
  margin-right: 1px;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
}

.dateIcon {
  color: var(--cbGreenFade)
}

.clearButton {
  background-color: var(--naDarkBlue);
  padding: 0.375rem 0.75rem 0.375rem 0;
  background-color: #30313D;
  display: flex;
  align-items: center;
}

.clearButton>.b-icon.bi {
  font-size: 150%;
}

.groupBorder {
  border: solid 1px var(--cbGreenFade);
  border-radius: 0.25rem;
}

.pickerText {
  padding-right: 0;
}

.datetimepicker.visible {
  z-index: 20;
}

.rowClass,.rowHClass{
  color: rgba(255, 255, 255, 0.7)
}
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['id', 'sessionid'],
  components: {ErrorLogging},
  data() {
    return {
      token: '',
      sessions: [],
      sessionsel: 0,
      fields: [
        { key: 'datetime', label: 'Date/Time', formatter: 'formatTime2', sortable: true },
        { key: 'connection_time', label: 'Connected', formatter: 'formatTime', sortable: true },
        { key: 'quality', label: 'Quality', sortable: true },
      ],
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.fetchSessions()
  },

 
  methods: {
   
    fetchSessions() {
      axios.get('/api/portalroversessions', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id,
          start: this.validStartDate,
          end: this.enddate,
          sessiondata: 0
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data;
          } else {
              this.sessions = response.data.Sessions
              if (this.sessionsel == 0) { this.sessionsel = this.sessionid }
                let session = this.sessions.filter(session => session.session_id == this.sessionsel)
                this.$emit('session', session[0])
            }
        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    showSession(event) {
      this.sessionsel = event.session_id
      let session = this.sessions.filter(session => session.session_id == this.sessionsel)
      this.$emit('session', session[0])
    },

    formatTime(start) {
      let days = parseInt(start / 86400)
      let hours = Math.trunc(parseInt(start - (days * 86400)) / 3600)
      let minutes = Math.trunc(parseInt((start - (days * 86400) - (hours * 3600)) / 60))
      let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60)))
      let duration = days + 'd ' + hours + 'h ' + minutes + 'm ' + secs + 's'
      return duration
    },

    formatTime2(datetime) {
      let time = moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
      return time
    },

  }

};

</script>