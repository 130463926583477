<template>
<div>

<b-row>  
        <b-col class="col-12 col-sm-6">
            <b-icon-broadcast-pin class="largeicontop" style="float:left;margin-right:25px" /><h4 style="margin-top:5px;">Network</h4>
        </b-col>
</b-row> 

<b-row class="mt-3">
        <b-col class="col-12 mt-1 p-0" ref="mapdiv" :style="{ height: mapheight2 + 'px'}">
            <div v-if="display">
            <MapLeafletBases
                :mapheight="mapheight"
                :mapwidth="mapwidth"
                />
            </div>    
       </b-col>
</b-row>

</div>	
</template>

<script>
    import MapLeafletBases from './MapLeafletBases'
    import { mapState } from 'vuex'
	export default {
        name: 'NetworkDisplay',
        components: {
            MapLeafletBases 
        },
        data(){
		return {
                display:false,
                mapheight:100,
                mapheight2:100,
                mapwidth:100
                };
                
                },

        beforeDestroy() {
            window.removeEventListener("resize",this.setMapWidth);
        },         

        mounted(){
            
            this.setMapWidth()
        },

        created(){
            window.addEventListener("resize", this.setMapWidth)
        },

        computed: mapState(['openmenu']),

        watch: {
            openmenu: function () {
                setTimeout(() => this.setMapWidth(), 500)
            }    
        }, 

        methods: {

            setMapWidth(){
                this.mapheight2 = window.innerHeight -300 // Set height of bordered box
                this.mapheight = this.mapheight2 -10 // Set leaflet map hieght
                this.mapwidth =  this.$refs['mapdiv'].clientWidth - 2 // Set leaflet map width
                this.display = true
            }

		}	   
	
	};

</script> 