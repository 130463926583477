<template>
  <div class="borderlight borderpadd" style="color: rgba(255, 255, 255, 0.7);">
    <b-container>

      <b-row>

        <b-col class="col-12">
          <b-row>
            <b-col class="col-9">
              <h6 style="color:rgba(255,255,255,0.7)">DATA USAGE</h6>
            </b-col>
            <b-col class="col-3 text-center"><b-icon-fullscreen @click.stop="fullScreen()" /></b-col>
          </b-row>

          <b-row class="mt-1" style="color:rgba(255,255,255,0.7);font-size:0.8em">
            <b-col class="col-9 col-sm-8 col-lg-8">Used {{ used }} {{ usedsuffix }} / {{ allowance }} {{ allowancesuffix
              }}
            </b-col>
            <b-col class="col-12 col-sm-4 col-lg-4">
              <SubsChart :data="data" />
            </b-col>

          </b-row>

        </b-col>

        <b-col class="col-6">
          <b-row class="mt-3" style="font-size:0.9em">
            <!-- <b-col class="col-12">{{ data[1].value }} {{ data[1].label }}</b-col>
                        <b-col class="col-12">{{ data[0].value }} {{ data[0].label }}</b-col>      -->

          </b-row>
        </b-col>

      </b-row>

      <b-modal ref="full-screen" size="md" title="DATA USAGE" hide-footer centered content-class="darkmodal">

        <b-row class="mt-1">
          <b-col class="col-9">

            <b-row class="mt-3" style="color:rgba(255,255,255,0.7);">
              <b-col class="col-9">Used {{ used }} {{ usedsuffix }}</b-col>
              <b-col class="col-9">Available {{ allowance }} {{ allowancesuffix }}</b-col>
            </b-row>

          </b-col>

          <b-col class="col-3">
            <SubsChart :data="data" />
          </b-col>
          <!-- <b-col class="col-12"># Sims {{ simms }}</b-col> -->
        </b-row>

      </b-modal>

    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>

<script>
import SubsChart from './SubsChart'
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['roverid'],
  name: 'CasterSimmsUsageCard',
  components: {
    SubsChart,
    ErrorLogging,
  },
  data() {
    return {
      token: '',
      data: [],
      total: 0,
      allowance: 0,
      allowancesuffix: '',
      used: 0,
      usedsuffix: '',
      simms: 0,
      display: false,
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.getData()
  },

  methods: {

    getData() {

      axios.get('/api/datausage', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          reseller: this.$store.getters.resellerid,
          dealer: this.$store.getters.dealerid,
          company: this.$store.getters.companyid,
          rover: this.roverid
        }
      })
        .then((response) => {
          if (response.data?.code == 999 || response?.data?.error == 'Unauthorized') {
            this.errorresponse = response.data;
            return;
          }

          this.data = response.data.data;
          this.allowance = response.data.allowance;
          if (this.allowance > 1000) {
            let allowance = this.allowance / 1000
            this.allowance = allowance.toFixed(2)
            this.allowancesuffix = 'GB'
          } else this.allowancesuffix = 'MB'

          this.used = response.data.used
          if (this.used > 1000) {
            let used = this.used / 1000
            this.used = used.toFixed(2)
            this.usedsuffix = 'GB'
          } else this.usedsuffix = 'MB'

          this.simms = response.data.simms
          this.display = true
          
        })
        .catch(err => {
          this.errorresponse = err;
        });
    },

    fullScreen() {
      this.$refs['full-screen'].show()
    },

  }

};

</script>