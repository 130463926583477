<template>
  <div>
    <b-container v-if="!sysadmin">
      <b-row>
        <b-col class="col-12 col-sm-6 offset-sm-3">

          <b-row class="mt-3">
            <b-col class="col-12">
              <h4>Edit User</h4>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="col-12 mt-3" v-if="primaryadmin">
              <h6>Main admin contact</h6>
            </b-col>

            <b-col class="col-12 col-md-6 mt-3">
              <label>First Name</label>
            </b-col>
            <b-col class="col-12 col-md-6 mt-3">
              <b-form-input 
                  type="search" 
                  placeholder="first name" 
                  autocomplete="off" 
                  v-model="user.fname"
                  name="user.fname" 
                  v-validate="{ required: true }"
                  :class="{ 'input': true, 'is-danger': errors.has('user.fname') }" 
                  class="lightinput"
                  @input="validateField('user.fname')" 
                  :disabled="updated"
                  />
              <span style="color:red;">{{ errors.first('user.fname') }}</span>
            </b-col>

            <b-col class="col-12 col-md-6 mt-3">
              <label>Last Name</label>
            </b-col>
            <b-col class="col-12 col-md-6 mt-3">
              <b-form-input 
                  type="search" 
                  placeholder="last name" 
                  autocomplete="off" 
                  v-model="user.lname"
                  name="user.lname" 
                  v-validate="{ required: true }"
                  :class="{ 'input': true, 'is-danger': errors.has('user.lname') }" class="lightinput"
                  @input="validateField('user.lname')"
                  :disabled="updated"
                   />
              <span style="color:red;">{{ errors.first('user.lname') }}</span>
            </b-col>


            <b-col class="col-12 col-sm-6 mt-3">
              <label>Email <span v-if="emailerror" style="color:red;margin-left:20px;"> ** EMAIL ALREADY EXISTS
                  **</span></label>
            </b-col>
            <b-col class="col-12 col-sm-6 mt-3">
              <b-form-input 
                  type="search" 
                  placeholder="login email" 
                  autocomplete="off" 
                  v-model="user.email"
                  name="user.email" 
                  v-validate="{ required: true }"
                  :class="{ 'input': true, 'is-danger': errors.has('user.email') }" 
                  class="lightinput"
                  @input="validateField('user.email')"
                  :disabled="updated" />
              <span v-if="emailerror2" style="color:red;">Invalid email format</span>
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
              <label>Phone</label>
            </b-col>
            <b-col class="col-12 col-sm-6 mt-3">
              <b-form-input 
                type="search" 
                placeholder="telephone" 
                autocomplete="off" 
                v-model="user.phone"
                class="lightinput"
                :disabled="updated" />
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
              <label>Mobile</label>
            </b-col>
            <b-col class="col-12 col-sm-6 mt-3">
              <b-form-input 
                  type="search" 
                  placeholder="mobile" 
                  autocomplete="off" 
                  v-model="user.mobile"
                  class="lightinput"
                  :disabled="updated"
                  />
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
              <label>Role</label>
            </b-col>
            <b-col class="col-12 col-sm-6 mt-3">
              <b-form-select 
                    v-model="user.role" 
                    :options="roles" 
                    class="lightinput" 
                    size="sm" 
                    name="user.role"
                    v-validate="{ min_value: 1 }" 
                    :class="{ 'input': true, 'is-danger': errors.has('user.role') }"
                    @change="validateField('user.role')"
                    :disabled="updated"
                     />
              <span v-if="errors.first('user.role')" style="color:red;">Select user role</span>

            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
              <label>Change Password </label>
            </b-col>
            <b-col class="col-12 col-sm-6 mt-3">
              <b-input-group>
                <b-form-input 
                    type="search" 
                    placeholder="new password" 
                    autocomplete="off" 
                    v-model="newpassword"
                    class="lightinput" size="md"
                    :disabled="updated"
                     />
                <b-input-group-append>
                  <b-button variant="light" :disabled="updated" @click="createPassword()"><b-icon-arrow-clockwise /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>


            <b-col class="col-6 mt-3">
              <label>Send User Email</label>
            </b-col>

            <b-col class="col-6 mt-2">
              <b-form-checkbox inline v-model="showsendemail" :disabled="updated" size="lg" value=1 unchecked-value=0 />
            </b-col>

            <b-col class="col-12 mt-3 mb-3" v-if="showsendemail == 1">
              <b-form-input type="search" placeholder="email address" :disabled="updated" autocomplete="off" v-model="emailto"
                class="lightinput" />
            </b-col>

       </b-row>
       
       <b-row v-if="!updated">

            <b-col class="col-12 col-md-5 mt-3">
              <b-button variant="outline-success" size="md" block @click="saveUser()"><b-icon-check-circle
                  class="mr-2" />Save</b-button>
            </b-col>

            <b-col class="col-12 col-md-5 offset-md-2 mt-3">
              <b-button variant="outline-warning" size="md" block @click="cancelUserProfile()"><b-icon-x-circle
                  class="mr-2" />Cancel</b-button>
            </b-col>

        </b-row>

        <b-row v-else>

        <b-col class="col-12 mt-3">
          <b-button variant="outline-success" size="md" block @click="finishUser()"><b-icon-check-circle
              class="mr-2" />User updated</b-button>
        </b-col>

        </b-row>

         
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else>
      <b-row>
        <b-col class="col-12 col-sm-6 offset-sm-3">

          <b-row class="mt-3">
            <b-col class="col-12">
              <h4>System Admin</h4>
              <h5>User Edit is not available</h5>
            </b-col>
          </b-row>

      </b-col>     
     </b-row>
    </b-container>  
    <SendEmail :sendmail="sendmail" :emailid="emailid" :emailto="emailto" :id="addeduser" :companyid="company.value"
      :showdiag=false :password="user.changepassword" />

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
    <LoadCompanyData :reloaddata="reloaddata" :dialog="false"/>
  </div>
</template>

<script>
import SendEmail from './SendEmail'
import ErrorLogging from './ErrorLogging'
import LoadCompanyData from './LoadCompanyData'
import axios from 'axios'
import { Validator } from 'vee-validate'

export default {
  name: 'UserEdit',
  components: {
    SendEmail,
    ErrorLogging,
    LoadCompanyData
  },
  data() {
    return {
      edit: false,
      token: '',
      userid: 0,
      passtest: { prop: '' },
      user: {},
      newpassword: '',
      usersel: 0,
      primaryadmin: false,
      userclass: 'stagecomp',
      companyclass: 'stagecomp',
      createclass: 'stagecomp',
      line1class: 'stageselectlinecomp',
      line2class: 'stageselectlinecomp',
      currentemail: '',
      showsendemail: 1,
      sendmail: false,
      emailto: '',
      emailid: 21, // Dealer 31 Company 21 User details email
      emailerror: false,
      emailerror2: false,
      stagemessages: [],
      errorresponse: {},
      modaltitle: '',
      addeduser: 0,
      updated:false,
      reloaddata:0,
      roles: [
        { value: 0, text: 'Select role' },
        { value: 20, text: 'Company Admin' },
        { value: 30, text: 'Company User' },
      ],
      sysadmin:false
    };

  },

  created() {
    this.userid = this.$store.getters.user.id
    this.token = this.$store.getters.token
    this.usersel = this.$route.params.id
    // Get stored company value and decouple object
    this.company = JSON.parse(JSON.stringify(this.$store.getters.company))

    if (this.usersel != 0) {
      this.user = this.company.users.filter(usersel => usersel.id == this.usersel)
     
      if(this.user.length == 0){
        this.sysadmin = true
      } else {this.user = this.user[0]}

    } else {
      this.user.role = 0
      this.user.value = 0
    }

    this.currentemail = this.user.email
    this.emailto = this.user.email
    this.user.changepassword = ''
    if (this.usersel == this.company.adminuser) { this.primaryadmin = true }
  },

  mounted() {
    Validator.localize('en', {
      messages: {
        required: '** required.',
      },
    })
  },

  methods: {

    saveUser() {
      this.validateAll()
    },

    validateAll() {
      if (!this.isValidEmail(this.user.email)) {
        this.emailerror2 = true
        return
      } else this.emailerror2 = false

      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.user.changepassword !== '') { this.user.password = this.user.changepassword }
          this.updateUserProfile()
        }
      })
    },

    validateField(field) {
      setTimeout(() => this.validateDebounced(field), 300)
    },

    validateDebounced(field) {
      this.$validator.validate(field)
      if (field == 'user.email') {
        this.emailCheck()
        this.emailto = this.user.email
      }
    },

    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    emailCheck() {
      if (this.currentemail !== this.user.email) {

        axios.get('/api/checkuserexists', {
          headers: { "Authorization": "Bearer " + this.token },
          params: {
            userid: this.$store.getters.user.id,
            token: this.token,
            email: this.user.email
          }
        })
          .then((response) => {

            if (response.data.code == 999) {
              this.errorresponse = response.data
            } else {

              if (response.data.exists == true) {
                this.emailerror = true
              } else {
                this.emailerror = false //this.$emit('dealer',this.dealer)
              }
            }

          })
          .catch((err) => {
            this.errorresponse = err;
          });
      }
    },

    createPassword() {
      let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
      let pwdLen = 8
      this.newpassword = Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('')
    },

    cancelUserProfile() {
      history.back()
    },

    updateUserProfile() {

      this.user.changepassword = this.newpassword

      axios({
        method: 'post',
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/portaluserprofile',
        data: {
          userid: this.$store.getters.user.id,
          token: this.token,
          user: this.user,
          company: this.company.value,
          companydetails: this.company
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.addeduser = response.data.user.id   // new user id to send email to

            if (this.user.value == 0) { this.company.users.push(response.data.user) } // add new user to company.users
            this.updated = true
            this.reloaddata = 1
            if (this.showsendemail == 1) { this.sendUserEmail() }
          }
        })
        .catch((err) => {
          this.errorresponse = err;
        });
    },

    sendUserEmail() {
      this.sendmail = !this.sendmail // trigger to SendEmail
    },

    finishUser() {
      history.back()
    }

  }

};
</script>