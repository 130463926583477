import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios';
axios.defaults.baseURL = 'https://ip-rtk-aws.com';
Vue.use(Vuex)

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, {
    fieldsBagName: 'veeFields',
    events: ''
});

import 'bootstrap'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


Vue.config.productionTip = false

import UserLogin from './components/UserLogin.vue'
import UserLogout from './components/UserLogout.vue'
import ResetPasswordForm from './components/ResetPasswordForm.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import DashBoard from './components/DashBoard.vue'
import HomePage from './components/HomePage.vue'
import NetworkDisplay from './components/NetworkDisplay.vue'
import RoverDisplay from './components/RoverDisplay.vue'
import RoverManage from './components/RoverManage.vue'
import UserEdit from './components/UserEdit.vue'
import RoverSessionHistory from './components/RoverSessionHistory.vue'
import RoversDisplay from './components/RoversDisplay.vue'
import RoversList from './components/RoversList.vue'
import PaymentHome from './components/PaymentHome.vue'
import BasketCheckout from './components/PaymentCheckout.vue'
import PaymentComplete from './components/PaymentComplete.vue'
import MachineEdit from './components/MachineEdit.vue'
import SubscriptionAssign from './components/SubscriptionAssign.vue'
import SubscriptionsList from './components/SubscriptionsList.vue'
import SubscriptionList from './components/SubscriptionList.vue'
import SubscriptionRenew from './components/SubscriptionRenew.vue'
import SubscriptionEdit from './components/SubscriptionEdit.vue'
import SubscriptionRenewCheckout from './components/SubscriptionRenewCheckout.vue'
import GDPRCompliance from './components/GDPRCompliance.vue'
import AdminSelectCompany from './components/AdminSelectCompany.vue'
import CompanyDetails from './components/CompanyDetails.vue'
import CompanyEdit from './components/CompanyEdit.vue'

Vue.component('home-landing', require('./components/HomeLanding.vue').default)

const router = new VueRouter({

    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        //reset scroll position on navigation, if you use back/foward remeber position
        if(savedPosition){ return savedPosition;}
        return { x: 0, y: 0 };
    },

    routes: [
        {
            path: '/',
            name: 'login',
            component: UserLogin
        },
        {
            path: '/logout',
            name: 'logout',
            component: UserLogout
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: ForgotPassword,
            meta: {
                auth: false
            }
        },
        {
            path: '/password/reset',
            name: 'reset-password-form',
            component: ResetPasswordForm,
            meta: {
                auth: false
            }
        },
        {
            path: '/compliance/:id',
            name: 'gdprcompliance',
            component: GDPRCompliance
        },
        {
            path: '/home',
            name: 'home',
            component: DashBoard,


    children: [
      {
        path: '/homepage', 
        name: 'homepage',
        component: HomePage, 
      },
      {
        path: '/roversdisplay', 
        name: 'roversdisplay',
        component: RoversDisplay, 
      },
      {
        path: '/rovers', 
        name: 'roverslist',
        component: RoversList, 
      },
      {
        path: '/networkdisplay', 
        name: 'networkdisplay',
        component: NetworkDisplay, 
      },
      {
        path: '/rover/:id', 
        name: 'roverdisplay',
        component: RoverDisplay, 
      },
      {
        path: '/rovermanage/:id', 
        name: 'rovermanage',
        component: RoverManage, 
      },
      {
        path: '/roverhistory/:id', 
        name: 'RoverSessionHistory',
        component: RoverSessionHistory, 
      },
      {
        path: '/machinery/:id', 
        name: 'machineedit',
        component: MachineEdit, 
      },
      {
        path: '/addrover/:id', 
        name: 'machinenew',
        component: MachineEdit, 
      },
      {
        path: '/subscriptions', 
        name: 'subscriptions',
        component: SubscriptionsList, 
      },
      {
        path: '/buysubscriptions', 
        name: 'subscriptionadd',
        component: PaymentHome
      },
      {
        path: '/useredit/:id', 
        name: 'useredit',
        component: UserEdit, 
      },
      {
        path: '/selectcompany', 
        name: 'adminselectcompany',
        component: AdminSelectCompany, 
      },
      {
        path: '/companydetails', 
        name: 'companydetails',
        component: CompanyDetails, 
      },
      {
        path: '/companyedit', 
        name: 'companyedit',
        component: CompanyEdit, 
      },
      {
        path: '/subscription/:id', 
        name: 'subscription',
        component: SubscriptionList 
      },
      {
        path: '/assignsubscription/:id', 
        name: 'assignsubscription',
        component: SubscriptionAssign, 
      },
      {
        path: '/renewsubscription/:id', 
        name: 'renewsubscription',
        component: SubscriptionRenew, 
      },
      {
        path: '/subscription/checkout', 
        name: 'renewcheckout',
        component: SubscriptionRenewCheckout, 
      },
      {
        path: '/editsubscription/:id', 
        name: 'subscriptionedit',
        component: SubscriptionEdit, 
      },
      {
        path: '/checkout', 
        name: 'basketcheckout',
        component: BasketCheckout, 
      },
      {
        path: '/paymentcomplete', 
        name: 'paymentcomplete',
        component: PaymentComplete, 
      },

            ]
        },


    ]

})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

import VueAnalytics from 'vue-analytics';
//and then use it in main.js
Vue.use(VueAnalytics, {
    id: 'G-K9WTDX1KL6',
    router
})