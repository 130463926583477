<template>
<div>

<b-row>
<b-col class="col-12 col-sm-6 offset-sm-3">
   
<b-row>
        <b-col class="col-12">
            <h4>Renew Subscription</h4>
        </b-col>
        <b-col class="col-12 mt-3">
            <h5>{{  rover.text }}</h5>
        </b-col>
</b-row>

<b-row>
    <b-col class="col-12 mt-3"><h6>Current subscription</h6></b-col>
    <b-col class="col-5 mt-3"><h6>{{ subtype.text }}</h6></b-col>  
    <b-col class="col-7 mt-3">
        <div class="rtmarkerexpiry" :style="{ backgroundColor:sub.expirycolor}" v-if="sub.status == 'Active'">
                {{ sub.status}} {{ sub.expirydays }} day<span v-if="sub.expirydays !== 1">s</span> remaining
        </div>
        <div class="rtmarkerexpiry" :style="{ backgroundColor:sub.color}" v-else>
                {{ sub.status}} 
        </div>
    </b-col>
</b-row>

<b-row>
    <b-col class="col-5 mt-3"><h6>Activated</h6></b-col>
    <b-col class="col-7 mt-3"><h6>{{ formatDate(sub.startdate2) }}</h6></b-col>  

    <b-col class="col-5 mt-3"><h6>Expiry</h6></b-col>
    <b-col class="col-7 mt-3"><h6>{{ formatDate(sub.enddate2) }}</h6></b-col>  

    <b-col class="col-12 mt-3" style="border-top:1px solid rgba(255,255,255,0.7)"></b-col>

</b-row> 

<b-row>

    <b-col class="col-12 mt-3"><h5>Select subscription</h5></b-col>

    <b-col class="col-12 mt-3">
            <label>Subscription</label>
                <b-form-select
                    v-model="subsel"
                    :options="subtypes"
                    name="subsel"
                    class="lightinput"
                    @change="setSub($event)"
                    v-validate="{min_value:1}"
                    :class="{'b-form-select': true, 'is-danger': errors.has('subsel') }"  
                    />
                    <span style="color:red" v-show="errors.has('subsel')">** required</span>
        </b-col>

</b-row>

<b-row class="mt-3">

    <b-col class="col-12 col-sm-6 mt-2">      
        <label>Activation Date</label>
    </b-col>            

    <b-col class="col-12 col-sm-6 mt-1">
            
        <VueCtkDateTimePicker
            v-model="datesel1"
            format='DD-MM-YYYY HH:mm'
            :dark="true"
            :no-value-to-custom-elem="true"
            :only-date="false"
            >
            <b-form-input 
                type="search"
                v-model="datesel1" 
                autocomplete = "off"
                placeholder="select"
                class="lightinput"
                name="datesel1"
                v-validate="{required:true}"
                :class="{'b-form-select': true, 'is-danger': errors.has('datesel1') }"
                :disabled="subsel == 0  || activatenow == 1"
              
                />
        </VueCtkDateTimePicker>    

    </b-col> 
    
    <b-col class="col-12 col-sm-6 mt-3">      
        <label>Expiration Date</label>
    </b-col>            

    <b-col class="col-12 col-sm-6 mt-2" v-if="subsel > 0"> 
           
            <VueCtkDateTimePicker
                        v-model="expire"
                        format='DD-MM-YYYY HH:mm'
                        :dark="true"
                        :no-value-to-custom-elem="true"
                        :only-date="false"
                        >
                        <b-form-input 
                            type="search"
                            v-model="expire" 
                            autocomplete = "off"
                            placeholder="-"
                            class="lightinput"
                            name="expire"
                            v-validate="{required:true}"
                            :class="{'b-form-select': true, 'is-danger': errors.has('expire') }"
                            disabled
                            />
                        </VueCtkDateTimePicker>
        </b-col> 

        <b-col class="col-12 col-sm-6 mt-3 pl-4" v-else> - </b-col> 

        <b-col class="col-12 mt-3">
                <b-form-checkbox
                    v-model="activatenow"
                    inline
                    value=1
                    unchecked-value=0
                    size="lg"
                    :disabled="subsel == 0"
              />
              <label style="margin-top:3px">Start from current expiry date/time</label>
            </b-col> 
     
       
        
</b-row>


<b-row class="pb-5">
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="validateSub()" block><b-icon-check-circle class="mr-2" />Proceed to checkout</b-button>
       </b-col>   
</b-row>   

</b-col>
</b-row>

</div> 
</template>

<script>
    import moment from 'moment'
    import axios from 'axios'
    export default {
        name:'SubscriptionAssign',
        components:{
        },
        data(){
        return {
                token:'',
                subtypes:[{value:0,text:'Select'}],
                subsel:0,
                sub:{},
                subtype:{},
                rover:{},
                expire:'',
                activatenow:1,
                datesel1:'',
                };
                
      },
      
      created(){
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.companyid = this.$store.getters.companyid
            
            // Get stored rover value and decouple object
            this.rover = JSON.parse(JSON.stringify(this.$store.getters.rovers)).filter(roversel => roversel.value == this.$route.params.id)[0]
            
            this.sub = this.rover.subscriptiondetail[0]
            this.subtype = this.sub.subscription[0]
            this.sub.rover = this.rover.value
            this.sub.port = this.rover.port
            this.sub.connection = this.rover.connection
            this.sub.username = this.rover.username
            this.sub.password = this.rover.password
            this.sub.mount = 'RTK_RTCM3'
            this.sub.needs_gga = this.rover.needs_gga
            this.sub.disconnect_onchangebase = this.rover.disconnect_onchangebase
            this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm')

            this.fetchSubs()
            },

        watch:{
            activatenow: function () {
                   if(this.activatenow == 1){ // start from current expiry
                       this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm')
                       this.setDate()
                    } else {                   // start from now
                        this.datesel1 = moment().format('DD-MM-Y HH:mm')
                        this.setDate()
                        }
                    this.sub.activatenow = this.activatenow    
                },
                
            datesel1: function () {
                    if(this.subsel != 0){
                    this.setDate()
                    }
                },

           subsel: function () {
            if(this.subsel != 0){
                    this.setDate()
                    }
             }     

         },     
  
       methods: {

        fetchSubs() {
            axios.get('/api/portalsubscriptionprices', {
                headers: { "Authorization": "Bearer " + this.token },
                params: {
                userid: this.userid,
                token: this.token,
                }
            })
                .then((response) => {
                if (response.data.code == 999) {
                    this.errorresponse = response.data;
                } else {
                    this.subtypes.push(...response.data.subs)
                }
                })
                .catch((err) => {
                this.errorresponse = err;
                });
    },

            setSub(event){  
                let data = this.subtypes.filter(sub => sub.value == event)[0]
               
                this.sub.id = event
                this.sub.type = data.type
                this.sub.subid = data.value
                this.sub.title = data.text
                this.sub.price = data.price
                this.sub.days = data.days
                this.sub.months = data.months
                this.sub.years = data.years
                
                this.$validator.validate('subsel')
               
                this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm')
                this.setDate() 

                // Add to basket for checkout
                this.basketsub = data
                this.subtotal = this.sub.price
                this.addtoBasket()

            },

            addtoBasket() {
                this.basketitems = []
                this.sub.stext = this.sub.text

                this.basketitems.push({ subid: this.sub.type, subtitle: this.sub.title, subquant: 1, subprice: this.sub.price, subtotal: this.sub.price, subsimm: 0 })
                this.$store.commit('setbaskettotal', this.sub.price)
                this.$store.commit('setbasket', this.basketitems)
               
            },

            setDate(){
                let subtype = this.subtypes.filter(sub => sub.value == this.subsel)[0]
                
                let datetime = moment(this.datesel1, "DD-MM-YYYY HH:mm")
              
                if(subtype.days !== 0){
                    this.sub.end = moment(datetime).add(subtype.days, 'days')
                }
                if(subtype.months !== 0){
                    this.sub.end = moment(datetime).add(subtype.months, 'months')
                }
                if(subtype.years !== 0){
                    this.sub.end = moment(datetime).add(subtype.years, 'years')
                }
                this.expire = moment(this.sub.end).format('DD-MM-YYYY HH:mm')
                this.sub.start = moment(datetime).format('DD-MM-YYYY HH:mm')
                this.sub.end = this.expire 

                this.$validator.validate('datesel1')
                //this.$validator.validate('expire')
            },

            formatDate(date){
                return moment(date).format('DD-MM-YYYY HH:mm')
            },

            validateSub(){
                this.$validator.validateAll().then(result => { 
                    if(result){this.renewSub()}
                })
             },

             renewSub(){  //  Activate subscription
                this.sub.start = moment(moment(this.sub.start, 'D-M-Y H:mm')).format('YYYY-MM-DD HH:mm')
                this.sub.end = moment(moment(this.sub.end, 'D-M-Y H:mm')).format('YYYY-MM-DD HH:mm')
                if(this.sub.needs_gga == 'No'){this.sub.needs_gga = 0} else this.sub.needs_gga = 1
                if(this.sub.disconnect_onchangebase == 'No'){this.sub.disconnect_onchangebase = 0} else this.sub.disconnect_onchangebase = 1
                this.$store.commit('setsubsel',this.sub) // save renewal details in store
                this.$router.push({ name: 'renewcheckout', params: {} }).catch(()=>{})
             },

           
            finish(){
                  history.back()
            },

		}	   
};
</script>