<template>
  <div>
    <b-row>
      <b-col class="col-12 col-sm-6 offset-sm-3">

        <b-row>
          <b-col class="col-12" v-if="roverid == 0">
            <h4>Add Rover</h4>
            <h5>{{ machine.displayname }}</h5>
          </b-col>

          <b-col class="col-12" v-else>
            <h4>Edit Rover</h4>
            <h5>{{ machine.displayname }}</h5>
          </b-col>

          <b-col class="col-12 mt-3">
            <label>Manufacturer</label>
            <b-form-select v-model="machine.make" :options="makers" name="machine.make" @change="makerSel"
              v-validate="{ min_value: 1 }" :class="{ 'b-form-select': true, 'is-danger': errors.has('machine.make') }"
              class="lightinput" :disabled="lockfield" />
            <span v-if="errors.has('machine.make')" style="color:red;">Select a manufacturer</span>

          </b-col>


          <b-col class="col-12 mt-3">
            <label>Model</label>
            <b-form-select v-model="machine.model" :options="models" name="machine.model" @change="modelSel"
              v-validate="{ min_value: 1 }" :class="{ 'b-form-select': true, 'is-danger': errors.has('machine.model') }"
              :disabled="lockfield" class="lightinput" />
            <span v-if="errors.has('machine.model')" style="color:red;">Select a model</span>

          </b-col>


          <b-col class="col-12 mt-3">
            <label>Reg Num</label>
            <b-form-input type="search" placeholder="reg num / id num" autocomplete="off" v-model="machine.regnum"
              :disabled="lockfield" class="lightinput" />

          </b-col>

          <b-col class="col-12 mt-3">
            <label>Receiver Serial Number </label>
            <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="machine.receiver_serial_num"
              class="lightinput" :disabled="lockfield" />

          </b-col>

          <b-col class="col-12 mt-3">
            <label>Modem Serial Number </label>
            <b-form-input type="search" placeholder="optional" autocomplete="off" v-model="machine.modem_serial_num"
              class="lightinput" :disabled="lockfield" />

          </b-col>

        </b-row>


        <b-row class="mt-5 pb-5" v-if="!updated">
          <b-col class="col-12 col-sm-5 mt-3">
            <b-button variant="outline-success" @click.stop="validateData()" block><b-icon-check-circle
                class="mr-2" />Save</b-button>
          </b-col>

          <b-col class="col-12 col-md-5 offset-md-2 mt-3">
            <b-button variant="outline-warning" block @click.stop="cancel()"><b-icon-x-circle
                class="mr-2" />Cancel</b-button>
          </b-col>
        </b-row>

        <b-row class="mt-5 pb-5" v-if="updated && roverid != 0">
          <b-col class="col-12">
            <b-button variant="outline-success" @click.stop="finish()" block><b-icon-check-circle class="mr-2" />Rover
              updated</b-button>
          </b-col>
        </b-row>

        <b-row class="mt-5 pb-5" v-if="updated && roverid == 0">
          <b-col class="col-12">
            <b-button variant="outline-success" @click.stop="finish()" block><b-icon-check-circle class="mr-2" />New
              rover created</b-button>
          </b-col>
        </b-row>


        <b-modal ref="edit-maker" size="md" :title="modaltitle" @hide="closemakermodal" hide-footer centered
          content-class="darkmodal">
          <CasterMachineMakerEdit :id=0 v-on:updatemakers="updatemakers" :makers=makers />
        </b-modal>

        <b-modal ref="edit-model" size="md" :title="modaltitle" @hide="closemodelmodal" hide-footer centered
          content-class="darkmodal">
          <CasterMachineModelEdit :id=0 :maker="machine.make" v-on:updatemodels="updatemodels" :models=models />
        </b-modal>

      </b-col>
    </b-row>

    <LoadCompanyData :reloaddata="reloaddata" />
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />

  </div>
</template>

<script>
import CasterMachineMakerEdit from './CasterMachineMakerEdit'
import CasterMachineModelEdit from './CasterMachineModelEdit'
import ErrorLogging from './ErrorLogging'
import LoadCompanyData from './LoadCompanyData'
import { Validator } from 'vee-validate'
import axios from 'axios'

export default {
  name: 'MachineEdit',
  components: {
    CasterMachineMakerEdit,
    CasterMachineModelEdit,
    ErrorLogging,
    LoadCompanyData
  },
  data() {
    return {
      token: '',
      userid: 0,
      companyid: 0,
      roverid: 0,
      machine: {},
      makers: [],
      models: [],
      modaltitle: '',
      updated: false,
      lockfield: false,
      errorresponse: {},
      reloaddata: 0,
    };
  },

  created() {
    this.userid = this.$store.getters.user.id
    this.token = this.$store.getters.token
    this.companyid = this.$store.getters.companyid
    this.roverid = this.$route.params.id

    // New machine
    if (this.roverid == 0) {
      this.machine.id = 0
      this.machine.make = 0
      this.machine.model = 0
      this.machine.company = this.companyid
      this.createNtripUsername()
      // Edit machine    
    } else {
      // Get rover values from store
      let rovers = JSON.parse(JSON.stringify(this.$store.getters.rovers)) // decouple store object
      this.machine = rovers.filter(rover => rover.value == this.roverid)
      this.machine = this.machine[0].machine[0]
      this.machine.company = this.companyid
      this.lockfield = false
    }

    this.fetchMakers()
    this.fetchModels()

  },

  mounted() {
    Validator.localize('en', {
      messages: {
        required: '** required.',
      },
    })
  },

  methods: {

    createNtripUsername() {
      axios.get('/api/getnewroverusername', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.userid,
          token: this.token,
          id: this.companyid
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data;
          } else {
            this.machine.displayname = response.data.username
            this.machine.username = response.data.username
            this.machine.password = this.createNtripPassword()
          }
        })
        .catch( (err) => {
          this.errorresponse = err;
        });
    },

    createNtripPassword() {
      let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz"
      let pwdLen = 8
      return Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('')
    },

    validateData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.roverid > 0) { this.updateMachine() } else this.newMachine()
        }
      })
    },

    newMachine() {

      axios({
        method: 'post',  //   New Machine
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/companymachine',
        data: {
          userid: this.userid,
          token: this.token,
          machinedata: this.machine,
          createrover: true
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data;
          } else {
            this.updated = true
            this.lockfield = true
            this.reloaddata = 2 // Trigger reload of rovers
          }
        })
        .catch( (err) => {
          this.errorresponse = err;
        });

    },

    updateMachine() {

      axios({
        method: 'put',  //   Update Machine
        headers: { "Authorization": "Bearer " + this.token },
        url: '/api/companymachine',
        data: {
          userid: this.userid,
          token: this.token,
          machinedata: this.machine
        }
      }).then((response) => {

        if (response.data.code == 999) {
          this.errorresponse = response.data
        } else {
          this.updated = true
          this.lockfield = true
          this.reloaddata = 2 // Trigger reload of rovers
        }
      })
      .catch( (err) => {
          this.errorresponse = err;
        });
    },

    cancel() {
      history.back()
    },

    fetchMakers() {
      axios.get('/api/machinemakers', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.userid,
          token: this.token
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.makers = []
            this.makers.push({ value: 0, text: 'Select' })
            this.makers.push({ value: 99999, text: 'Add new manufacturer' })
            this.makers.push(...response.data.makers)
          }
        })
        .catch( (err) => {
          this.errorresponse = err;
        });
    },

    fetchModels() {

      axios.get('/api/machinemodels', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          maker: this.machine.make
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {
            this.models = []
            this.models.push({ value: 0, text: 'Select' })
            this.models.push({ value: 99999, text: 'Add new model' })
            this.models.push(...response.data.models)
          }
        })
        .catch( (err) => {
          this.errorresponse = err;
        });
    },

    makerSel(event) {
      if (event == 99999) {
        this.modaltitle = 'New Manufacturer'
        this.$refs['edit-maker'].show()
      } else {
        this.lockfield = false
        let maker = this.makers.filter(maker => maker.value == event)

        this.machine.make_title = maker[0].text
        this.machine.make = event
        this.machine.model = 0

        this.$validator.validate('machine.make')
        this.fetchModels()
      }
    },

    closemakermodal() {
      this.machine.make = 0 // set selection back to select
    },

    closemodelmodal() {
      this.machine.model = 0 // set selection back to select
    },

    modelSel(event) {
      if (event == 99999) {
        this.modaltitle = 'New Model'
        this.$refs['edit-model'].show()
      } else {
        let model = this.models.filter(model => model.value == event)
        this.machine.model_title = model[0].text
        this.machine.model = event
        this.$validator.validate('machine.model')
      }
    },

    updatemakers(value) {
      this.lockfield = false
      this.$refs['edit-maker'].hide()
      this.machine.make = value
      this.fetchMakers()
    },

    updatemodels(value) {
      this.$refs['edit-model'].hide()
      this.machine.model = value
      this.fetchModels()
    },

    finish() {
      history.back()
    },
  }
};
</script>