<template>
  <div class="borderlight borderpadd" style="color: rgba(255, 255, 255, 0.7);">
    <b-container>

      <b-row>
        <b-col class="col-12">CONNECTION TIME (HRS)</b-col>
      </b-row>

      <b-row v-if="display">
        <b-col class="col-12 mt-3">
          <BarChartSingle :chartdata="data" :height="200" />
        </b-col>

      </b-row>

      <b-row class="mt-3" v-else>
        <b-col class="col-12 text-center pt-3 pb-3">
          <h5 style="color:#48A1BA;">No Data</h5>
        </b-col>
      </b-row>
    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>

<script>
import BarChartSingle from './BarChartSingle'
import axios from 'axios'
import ErrorLogging from './ErrorLogging.vue';

export default {
  props: ['roverid'],
  name: 'CasterRoverConnectionTimeChartCard',
  components: {
    BarChartSingle,
    ErrorLogging,
  },
  data() {
    return {
      id: 0,
      data: [],
      token: '',
      display: false,
      errorresponse: {},
    };

  },

  created() {
    this.token = this.$store.getters.token
    this.getData()
  },

  methods: {


    getData() {

      axios.get('/api/roversessiondata', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          rover: this.roverid,
          type: 2
        }
      })
        .then((response) => {
          if(response.data == 'NOT AUTHORISED'){
            this.$router.push({ name: 'logout', params: {} }).catch(() => { })
          }

          if (response.data.code == 999) {
            this.errorresponse = response.data
            return;
          }

          this.data = response.data;
          this.display = true;
        })
        .catch(err => {
          this.errorresponse = err;
        });
    },
  }

};
</script>