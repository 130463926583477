<template>
<div>

<b-container>

  <b-row>
    <b-col class="col-12 col-sm-6 offset-sm-3">

    <b-row>
      <b-col class="col-12">
              <h3>Checkout</h3>
      </b-col>
    </b-row>
     
    <b-row>

      <b-col class="col-12  mt-5">
              <PaymentStripeEmbed :formerrors="false" v-on:paymentcomplete="paymentComplete" />
      </b-col>

      <b-col class="col-12  p-0">  
                <PaymentBasket />
      </b-col>
    
      <b-col class="col-12 col-sm-6 offset-sm-3 mt-5">
         <img :src="stripelogo" style="width:90%;margin-left:5%">  
       </b-col>
      
    </b-row>
 
<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-col>
</b-row>

</b-container>

</div>
</template>

<script>
import PaymentStripeEmbed from './PaymentStripeEmbed'
import PaymentBasket from './PaymentBasket'
import ErrorLogging from './ErrorLogging'
import { mapState } from 'vuex'
import stripelogo from '@/assets/images/stripe-badge-transparent.png'
export default {
  name: 'PaymentCheckout',
  components:{
    PaymentStripeEmbed,
    PaymentBasket,
    ErrorLogging
  },
  data: function () {
    return {
      stripelogo,
      total:0,
      test:false,
      errorresponse:{}
    }; 
},
mounted() {
    if(this.$store.getters.user.role == 1){this.test = true} else this.test = false
},

computed: mapState(['baskettotal','paymentcomplete']),  

watch: {
            baskettotal() {

               if(this.baskettotal == 0 && !this.paymentcomplete){
                  history.back()
               } 
            }
},

methods: {

    paymentComplete(data){
          this.$store.commit('setpaymentcomplete',true)
          this.bitems = this.$store.getters.basket
          data.basket = this.bitems
          this.$store.commit('setpayment',data)
          this.$router.push({ name:'paymentcomplete', params: {} }).catch(()=>{})
    },

   
  },
};
</script>