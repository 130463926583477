<template>
  <div class="mainpage">

    <b-container style="margin-top:100px" v-if="!processed">

      <b-row>
        <b-col class="col-12 mt-3 mb-3">
          <h4>GDPR Compliance Form</h4>

          <p>I Understand Cloudbase Positioning Ltd process my information and data, Under the UK General Data
            Protection Regulation (UK GDPR), the lawful base we rely on for processing this information is: We have a
            legitimate interest.</p>

        </b-col>

        <b-col class="col-12 col-sm-2 mt-1 mb-sm-1">Company:</b-col>
        <b-col class="col-12 col-sm-10 mt-1">{{ company.title }} </b-col>

        <b-col class="col-12 col-sm-2 mt-1 mb-1">Address:</b-col>
        <b-col class="col-12 col-sm-10 mt-1">
          <b-row>
            <b-col class="col-12">{{ company.address1 }} </b-col>
            <b-col class="col-12">{{ company.address2 }} </b-col>
            <b-col class="col-12">{{ company.address3 }} </b-col>
            <b-col class="col-12">{{ company.towncity }} </b-col>
            <b-col class="col-12">{{ company.county }} {{ company.postcode }} </b-col>
          </b-row>
        </b-col>

        <b-col class="col-12 col-sm-2 mt-1">Email:</b-col>
        <b-col class="col-12 col-sm-10 mt-1">{{ company.email }} </b-col>

        <b-col class="col-12 col-sm-2 mt-1">Phone:</b-col>
        <b-col class="col-12 col-sm-10 mt-1">{{ company.mobile }} </b-col>


      </b-row>

      <b-row class="mt-5">


        <b-col class="col-1">
          <b-form-checkbox v-model="gdpr.terms" size="lg" value=1 unchecked-value=0 name="gdpr.terms"
            v-validate="{ min_value: 1 }" @change="validateField('gdpr.terms')" />
        </b-col>

        <b-col class="col-11" style="padding-top:3px">
          <p>I acknowledge that I have read and understand the <a
              href="https://cloudbase-rtk.net/end-user-licence-terms//">Terms & Conditions</a></p>
          <span v-if="errors.first('gdpr.terms')" style="color:red;">**required</span>
        </b-col>

        <b-col class="col-1 mt-3">
          <b-form-checkbox v-model="gdpr.privacy" size="lg" value=1 unchecked-value=0 name="gdpr.privacy"
            v-validate="{ min_value: 1 }" @change="validateField('gdpr.privacy')" />
        </b-col>

        <b-col class="col-11 mt-3" style="padding-top:3px">
          <p>I acknowledge that I have read and understand the <a
              href="https://cloudbase-rtk.net/end-user-privacy-policy/">Privacy Policy</a></p>
          <span v-if="errors.first('gdpr.terms')" style="color:red;">**required</span>
        </b-col>

        <b-col class="col-1 mt-3">
          <b-form-checkbox v-model="gdpr.marketing" value=1 unchecked-value=0 size="lg" />
        </b-col>

        <b-col class="col-11 mt-3" style="padding-top:3px">
          <p>I agree to accept marketing emails (optional)</p>
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-4 mt-3">

          <label>Name</label>
          <b-form-input type="search" placeholder="required" autocomplete="off" v-model="gdpr.name" name="gdpr.name"
            v-validate="{ required: true }" :class="{ 'darkinput': true, 'is-danger': errors.has('gdpr.name') }"
            @input="validateField('gdpr.name')" />
          <span v-if="errors.first('gdpr.name')" style="color:red;">**required</span>
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-4 mt-3">

          <label>Position</label>
          <b-form-input type="search" placeholder="required" autocomplete="off" v-model="gdpr.position"
            name="gdpr.position" v-validate="{ required: true }"
            :class="{ 'darkinput': true, 'is-danger': errors.has('gdpr.position') }"
            @input="validateField('gdpr.position')" />
          <span v-if="errors.first('gdpr.position')" style="color:red;">**required</span>
        </b-col>

        <b-col class="col-12 col-sm-6 col-md-4 mt-3">

          <label>Date</label>
          <b-form-input type="search" autocomplete="off" v-model="gdpr.date_accepted" class="darkinput" disabled />

        </b-col>

        <b-col class="col-12 mt-5">
          For enquiries related to data protection, please contact sales@cloudbase-rtk.net.
        </b-col>

      </b-row>

      <b-row>
        <b-col class="col-12 col-sm-6 mt-5">
          <b-button variant="success" block @click.stop=" validateAll()">ACCEPT</b-button>
        </b-col>

        <b-col class="col-12 col-sm-6 mt-5">
          <b-button variant="danger" block @click.stop="validateAll(3)">DECLINE</b-button>
        </b-col>
      </b-row>

      <b-modal ref="decline-terms" size="md" title="Warning" hide-footer centered content-class="darkmodal">

        <b-row>
          <b-col class="col-12">
            <h5>By declining our GDPR compliance you will not be able to use the Cloudbase Positioning system</h5>
          </b-col>

          <b-col class="col-12 mt-3 mb-3">
            <h5>Are you sure you want to do this?</h5>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 col-sm-6 mt-5">
            <b-button variant="success" block @click.stop="updateComp(3)">YES</b-button>
          </b-col>

          <b-col class="col-12 col-sm-6 mt-5">
            <b-button variant="danger" block @click.stop="hideDecline()">NO</b-button>
          </b-col>
        </b-row>

      </b-modal>

    </b-container>

    <b-container style="margin-top:100px" v-else>

      <b-row>
        <b-col class="col-12 mt-3 mb-3">
          <h4>GDPR Compliance Form</h4>
          <h5>Your form has been processed</h5>
        </b-col>
      </b-row>

    </b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" /> 

  </div>
</template>

<style scoped>
a {
  text-decoration: underline;
  color: white;
}
</style>

<script>
import axios from 'axios'
import { Validator } from 'vee-validate'
import ErrorLogging from './ErrorLogging.vue';

export default {
  name: 'GDPRCompliance',
  components:{
    ErrorLogging,
  },
  data() {
    return {
      token: '',
      code: '',
      gdpr: {},
      company: {},
      processed: false,
      errorresponse: {},
    };

  },

  mounted() {
    Validator.localize('en', {
      messages: {
        required: '** required.',
      },
    })
  },

  created() {
    this.code = this.$route.params.id
    this.fetchComp()
  },


  methods: {

    setDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1; // Months are zero-based, so we add 1
      let day = today.getDate();
      this.gdpr.date_accepted = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    },

    fetchComp() {
      axios.get('https://ip-rtk-aws.com/api/castergetcompliance', {
        params: {
          id: this.code
        }
      })
        .then((response) => {
          if (response?.data?.code == 999) {
            this.errorresponse = response.data;
            return;
          }

          this.gdpr = response.data.gdpr
          this.setDate()
          this.company = response.data.company

        })
        .catch(err => {
          this.errorresponse = err;
        });
    },

    validateAll(decline) {
      this.$validator.validateAll().then(result => {

        if (result) {
          if (decline == 3) {
            this.$refs['decline-terms'].show()
          } else this.updateComp(1)
        }
      })
    },

    validateField(field) {
      this.$validator.validate(field)
    },

    updateComp(decline) {

      this.$refs['decline-terms'].hide()
      this.gdpr.status = decline

      axios({
        method: 'post',
        url: 'https://ip-rtk-aws.com/api/casterupdatecompliance',
        //headers: {"Authorization" : "Bearer " + this.$store.getters.token},
        data: this.gdpr
      })
        .then((response) => {
          if (response?.data?.code == 999 || !response?.data?.code == 200) {
            this.errorresponse = response.data;
            return;
          }

          this.processed = true;

        })
        .catch(err => {
          this.errorresponse = err;
        });
    },

    hideDecline() {
      this.$refs['decline-terms'].hide()
    }
  }
};

</script>